/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { PageTitle } from "../../../_start/layout/core";
import { useFormik } from "formik";
import { InitContactForm } from "./helper/InitContactUsForm";
import { ValidateContactUsForm } from "./helper/ValidateContactUsForm";
import {sendMail} from "../../modules/contactus/redux/ContactUsCRUD";

export const Contactus: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: InitContactForm,
    validationSchema: ValidateContactUsForm,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      setTimeout(() => {
        sendMail(values).then(() => {
          alert("Thank you for contacting us. We'll get back to you soon");
          setStatus("Thank you for contacting us. We'll get back to you soon");
          setSubmitting(false);
          setLoading(false);
          resetForm();
          })
          .catch((err1) => {
            setSubmitting(false);
            setLoading(false);
          });
      }, 300);
    },
  });

  return (
    <>
      <div className="contact-page">
        <div className="contact-info">
          <div className="contact-wrap">
            <div className="top-cols">
              <h3 className="col-title">Contact Us</h3>
              <ul className="contact-detail">
                <li className="item">
                  <a href="#" className="links" target="_blank" rel="noreferrer">
                    <span className="fas fa-map-marker-alt link-icon"></span>
                    424, Shiven Square, Opp. Pal RTO, Adajan, Surat Pincode –
                    395009
                  </a>
                </li>
                <li className="item">
                  <a
                    href="mailto:info@pocketsite.me"
                    className="links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fas fa-envelope link-icon"></span>
                    info@pocketsite.me
                  </a>
                </li>
                <li className="item call">
                  <a
                    href="tel:+91 8758502693"
                    className="links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-solid fa-phone link-icon"></span>
                    +91 8758502693
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-connect d-flex justify-content-center">
              <ul className="list-unstyled social-items m-0">
                <li className="item">
                  <a href="#" target={"_blank"} className="social-link">
                    <span className="fab fa-facebook social-icon"></span>
                  </a>
                </li>
                <li className="item">
                  <a href="#" target={"_blank"} className="social-link">
                    <span className="fab fa-linkedin-in social-icon"></span>
                  </a>
                </li>
                <li className="item">
                  <a href="#" target={"_blank"} className="social-link">
                    <span className="fab fa-instagram social-icon"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-form-info">
            <h3 className="col-title">Get in touch</h3>
            <p className="label">Free feel drop a line below.</p>
            <div className="contact-form">
              <form onSubmit={formik.handleSubmit} noValidate>
                {formik?.status && (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                <div className="mb-8 floating-input-container">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                    className="form-control"
                    placeholder=" "
                    value={formik.values.name}
                  />
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="float-label"
                  >
                    * Name
                  </label>
                  {formik.touched.name && formik.errors.name !== "" && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className="mb-8 floating-input-container">
                  <input
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                    className="form-control"
                    placeholder=" "
                    value={formik.values.email}
                  />
                  <label
                    htmlFor="exampleFormControlInput2"
                    className="float-label"
                  >
                    * Email
                  </label>
                  {formik.touched.email && formik.errors.email !== "" && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  )}
                </div>
                <div className="mb-8 floating-input-container">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="mobile_number"
                    className="form-control"
                    placeholder=" "
                    value={formik.values.mobile_number}
                  />
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="float-label"
                  >
                    * Mobile Number
                  </label>
                  {formik.touched.mobile_number &&
                    formik.errors.mobile_number !== "" && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.mobile_number}
                        </div>
                      </div>
                    )}
                </div>
                <div className="mb-8 floating-input-container">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="subject"
                    className="form-control"
                    placeholder=" "
                    value={formik.values.subject}
                  />
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="float-label"
                  >
                    * Subject
                  </label>
                  {formik.touched.subject && formik.errors.subject !== "" && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.subject}
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-8 floating-input-container">
                  <textarea
                    id="message"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="message"
                    placeholder=" "
                    value={formik.values.message}
                  ></textarea>
                  <label
                    htmlFor="exampleFormControlInput4"
                    className="float-label"
                  >
                    * Message
                  </label>
                  {formik.touched.message && formik.errors.message !== "" && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.message}</div>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary fs-4 text-uppercase"
                  id="kt_login_signin_form_submit_button"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading ? (
                    <span className="indicator-label">Submit</span>
                  ) : (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <PageTitle>Contact us</PageTitle>
    </>
  );
};
