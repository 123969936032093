import React from "react";
import "../scss/template/edit-template.scss";
import SVG from "react-inlinesvg";
// Handle icons
import ArrowLeft from "../templates/images/icons/arow-left.svg";
import ArrowRight from "../templates/images/icons/arow-right.svg";

type Props = {
  handlePreviousClick: React.MouseEventHandler<SVGElement>;
  handleNextClick: React.MouseEventHandler<SVGElement>;
  templateList: number;
  currentTemplate: number;
};

const TempPrevNext = (props: Props) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="move-btn-label">Card</div>
        <div className="move-btn-wrap">
          <button className="btn pe-0" type="button" id="btn-prev">
            <SVG src={ArrowLeft} onClick={props.handlePreviousClick} />
          </button>
          <span className="btn pe-1" style={{ paddingLeft : "10px" }}> {props.currentTemplate} / {props.templateList}</span>
          <button className="btn pe-1" type="button" id="btn-next">
            <SVG src={ArrowRight} onClick={props.handleNextClick} />
          </button>
        </div>
      </div>
    </>
  );
};
export default TempPrevNext;
