 
 
 
function Videosection() {
  return (
    <>
      <div className="video-section" id="video">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading-medium mb-4 mb-lg-5">
                See, how simple it is to create & share
              </h2>
              <div className="video-wrap">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/sOJFYchBPSc"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Videosection;
