import SVG from "react-inlinesvg";
import Buttons from "../components/Buttons";
import "../scss/template/edit-template.scss";
import SocialMedia from "../components/edit-template/SocialMedia";
import Switch from "../components/edit-template/Switch";
import InputField from "../components/edit-template/InputField";
import whatsapp02 from "../templates/images/icons/whatsapp02.svg";
import instagram02 from "../templates/images/icons/instagram02.svg";
import youtube02 from "../templates/images/icons/youtube02.svg";
import twitter02 from "../templates/images/icons/twitter02.svg";
import facebook02 from "../templates/images/icons/facebook02.svg";
import close from "../templates/images/icons/close.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import * as template from "../app/modules/template/redux/TemplateListRedux"
import { RootState } from "../setup";
import { ServicesListModel } from "../app/modules/template/models/ServicesModel";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { initTemplateUserData } from "../app/pages/templates/helper/InitTemplateData";
import { toast } from "react-toastify";
import { addTemplateUserData } from "../app/modules/template/redux/TemplateUserDataCRUD";
import { TemplateValidate } from "../app/pages/templates/helper/TemplateValidate";
import { UploadFileNew } from "../components/edit-template/UploadFile";
import TempPrevNext from "../components/TempPrevNext";
import SelectDropdown from "../components/SelectDropdown";
import Template06 from "../templates/Template06";
import Template07 from "../templates/Template07";
import Template08 from "../templates/Template08";
import Template09 from "../templates/Template09";
import Template010 from "../templates/Template010";
import { CirclePicker } from "react-color";
import { colors } from "../app/helper/constant";
import Accordion from "react-bootstrap/Accordion";
import { UploadLogoFile } from "../components/edit-template/UploadLogoFile";
import Modal from "react-bootstrap/Modal";
import { TemplateUserDataRedux } from "../app/modules/template/redux/ReduxDispatchExport";
import CropProfileImage from "../app/pages/templates/components/Templates/common/Crop/CropProfileImage";
import CropCompanyLogo from "../app/pages/templates/components/Templates/common/Crop/CropCompanyLogo";
import { OnHideFunction } from "../PocketWebsite";
import Loader from "../_start/helpers/components/Loader";

type Props = {
  templateId: number;
  handleNextClick: React.MouseEventHandler<SVGElement>;
  handlePreviousClick: React.MouseEventHandler<SVGElement>;
  templateList: number,
  currentTemplateNumber: number,
};

const templateValidationSchema = TemplateValidate;

const EditTemplateNew: React.FC<Props> = ({
  children,
  templateId,
  handleNextClick,
  handlePreviousClick,
  templateList,
  currentTemplateNumber
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let tempIndexServiceList: any = [0, 0, 0, 0];
  let secondaryColor: string;
  const serviceRefFocus1 =  useRef<any>(null)
  const serviceRefFocus2 =  useRef<any>(null)
  const serviceRefFocus3 =  useRef<any>(null)
  const serviceRefFocus4 =  useRef<any>(null)
  
  const root = document.documentElement;
  
  const templateUserData: TemplateUserDataModel = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
    );
  const fromEdit: boolean = useSelector(
    (state: RootState) => state.templateUserData.fromEdit!
    );
    const serviceList: ServicesListModel[] = useSelector(
      (state: RootState) => state.templateUserData.templateServicesList!
      );
      const isAuthorized: boolean = useSelector(
    (state: RootState) => state.auth.isAuthorized!
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showColorPickerModal, setShowColorPickerModal] = useState<boolean>(false);
  const [selectedServiceList, setSelectedServiceList] = useState<any>(tempIndexServiceList);
  const [width, setWidth] = useState(window.innerWidth);

  // image src
  const [src, setSrc] = useState('');
  const [src1, setSrc1] = useState('');
  // preview
  const [preview, setPreview] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [, setProfileImageUrl] = useState<any>("");
  const [, setLogoImageUrl] = useState<any>("");
  const [logoShape, setLogoShape] = useState<
    "circle" | "circular" | "rounded" | "square" | undefined
  >();
  const [showCroper, setShowCroper] = useState<boolean>(false);
  const [profileImageIsLoading, setProfileImageIsLoading] = useState<boolean>(false);
  const [companyLogoIsLoading, setCompanyLogoIsLoading] = useState<boolean>(false);
  const [showCropperFor, setShowCropperFor] = useState<string>("companyLogo");
  const [changeEvent, setChangeImageEvent] = useState<boolean>(false);
  const [whatsAppLinkEdited, setWhatsAppLinkEdited] = useState<boolean>(false);
  const [, setFileNew] = useState("");
  const [uploadProfileImageFile, setUploadProfileImageFile] = useState<any>();
  const [uploadCompanyLogoFile, setUploadCompnayLogoFile] = useState<any>(); 
  const [loader, setLoader] = useState<boolean>(false); 
  const [show, setShow] = useState(false);

  const handleClose: OnHideFunction = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(!isAuthorized){
      navigate("/auth/login")
    }
  },[isAuthorized, navigate])
  const formik = useFormik({
    initialValues: isAuthorized
      ? templateUserData
      : templateUserData
      ? templateUserData
      : initTemplateUserData,
    validationSchema: templateValidationSchema,
    onSubmit: (values: any, { setSubmitting }: any) => {
      setLoading(true);
      setTimeout(async() => {
        if (isAuthorized) {                          
            if(!templateUserData?.service1?.name && !templateUserData?.service2?.name && !templateUserData?.service3?.name && !templateUserData?.service4?.name){
              toast.error('Error...! Atleast one Service / Item is required',{
                autoClose: 6000,
              })
              setLoading(false);
            }else{       
            addTemplateUserData({
              ...values,
              id: templateUserData.id,
              status: "Published",
              logoShape: templateUserData?.logoShape,
              selectedTemplateID: templateId ? templateId : templateUserData?.selectedTemplateID, 
              // companyLogo: logoImageUrl
              //   ? logoImageUrl
              //   : templateUserData?.companyLogo,
              // profileImage: profileImageUrl
              //   ? profileImageUrl
              //   : templateUserData?.profileImage,
              profile_image: uploadProfileImageFile?.data?.id ? uploadProfileImageFile?.data?.id : templateUserData?.profile_image?.filename_disk,
              company_logo: uploadCompanyLogoFile?.data?.id ? uploadCompanyLogoFile?.data?.id : templateUserData?.company_logo?.filename_disk,
              templateColor: templateUserData?.templateColor,
              templateSecondaryColor: templateUserData?.templateSecondaryColor ? templateUserData?.templateSecondaryColor : "#f4f7fc",
              croppedAreaLogo: templateUserData.croppedAreaLogo,
              croppedAreaProfile: templateUserData.croppedAreaProfile,
            })
              .then(async (res) => {
                setLoading(false);
                dispatch(
                  templateUserDataRedux.actions.setTemplateUserData({
                    ...templateUserData,
                    status: "Published",
                    templateColor: templateUserData?.templateColor,
                    templateSecondaryColor: templateUserData?.templateSecondaryColor
                  })
                );
                dispatch(templateUserDataRedux.actions.unsetFromEdit());
                setTimeout(() => {
                  navigate(`/profile/preview`);
                }, 300);
              })
              .catch((e) => {
                setLoading(false);
                setSubmitting(false);
                dispatch(templateUserDataRedux.actions.unsetFromEdit());
              });
            }
        } else {
          dispatch(templateUserDataRedux.actions.setFromEdit(true));
          setTimeout(() => {
            navigate(`/auth/login`, {
              state: {
                mobileNumber: values.mobileNumber,
              },
            });
          }, 300);
        }
      }, 100);
    },
  });

  useEffect(() => {
    if (!isAuthorized) {
      navigate("/auth/login");
    } 
    if (isAuthorized) {
      dispatch(template.action.getTemplateList());
      dispatch(templateUserDataRedux.actions.getServicesList(""));
    }
  }, [fromEdit]);

  useEffect(() => {
    if(isAuthorized){
      dispatch(
        templateUserDataRedux.actions.setTemplateUserData({
          ...formik.values,
          selectedTemplateID: templateUserData?.selectedTemplateID,
        })
      );
    }
  }, [formik.values, templateUserData?.selectedTemplateID]);

  useEffect(() => {
    if(isAuthorized){
      formik.setValues({ ...formik.values, selectedTemplateID: templateId });
      if(formik?.values?.whatsAppLink === null || formik?.values?.whatsAppLink === undefined){
        formik.setValues({
          ...formik.values,
          whatsAppLink: formik.values?.mobileNumber
        })
      }
    }
    pushSelectedService()
  }, [templateId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const performAdditionalFunctionality = (file: any) => {
    return new Promise((resolve) => {
      // Create a new Image object
      const image = new Image();
      // Set the source of the image
      image.src = file;
          // Wait for the image to load
          image.onload = async () => {
            setLoader(true)
            // Retrieve the width and height of the image
            const width = image.width;
            const height = image.height;
            // Use the width and height as needed
            const aspectRatio = width / height;
            if (aspectRatio > 1.2) {
              setLogoShape('circle')
              dispatch(
                TemplateUserDataRedux.actions.setTemplateUserData({
                  ...templateUserData,
                  logoShape: 'circle'
                })
              );
            } else {
              setLogoShape('square')
              dispatch(
                TemplateUserDataRedux.actions.setTemplateUserData({
                  ...templateUserData,
                  logoShape: 'square'
                })
              );
            }
            }; 
      // Simulating an asynchronous operation
      setTimeout(() => {
        resolve('');
      }, 2000); // Add your desired delay here
    });
  };

  // handle Change
  const handleImgChange: any = async (e: any, imgFor: string) => {   
    if(e.target.files[0] !== undefined){
      if(imgFor === "companyLogo"){
        // Pause execution for additional functionality (e.g., perform some async operations)
        await performAdditionalFunctionality(e.target.files[0] && URL.createObjectURL(e.target.files[0]));
        setLoader(false)
        setCompanyLogoIsLoading(false)
        setFile1(e.target.files[0]);
        setSrc1(URL.createObjectURL(e.target.files[0]));
        setShowCropperFor("companyLogo");
        // setLogoShape(logoShape);
        setChangeImageEvent(true)
        setCompanyLogoIsLoading(true);
          formik.setValues({
            ...formik.values,
            isCompanyLogo: true,
          }); 
        // setPreview(e.target.files[0] && URL.createObjectURL(e.target.files[0]))
        convertBase64(e.target.files[0]).then((res: any)=> {
          dispatch(
            TemplateUserDataRedux.actions.setTemplateUserData({
              ...templateUserData,
              // companyLogo: res,
              isCompanyLogo: true
            })
            );
          })
          setShowCroper(true);    
      }else{
        setFile(e.target.files[0]);
        setSrc(e.target.files[0] && URL.createObjectURL(e.target.files[0]));
        // setLogoShape(logoShape);
        setChangeImageEvent(true)
        setShowCropperFor("profileImage");
        setProfileImageIsLoading(true);
        setPreview(e.target.files[0] && URL.createObjectURL(e.target.files[0]))
        convertBase64(e.target.files[0]).then((res: any )=> {
          dispatch(
            TemplateUserDataRedux.actions.setTemplateUserData({
              ...templateUserData,
              // profileImage: res,
            })
            );
          })
          setShowCroper(true);
      }
    }
  };

  const convertBase64 = (file : any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const onImageChange = async (
    img: string , 
    imgFor: string,
    croppedImgArea: any,
    file: any,
    path: any,
    nFile: any,
  ) => {
    var reader = new FileReader();

    if (imgFor === "profileImage") {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64String = reader.result;
        setProfileImageUrl(base64String);
        setUploadProfileImageFile(path);
        dispatch(
          TemplateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            // profileImage: img,
            profile_image: path?.data,
          })
        );
      };      
      setFileNew(URL.createObjectURL(nFile));
      formik.setValues({
        ...formik?.values,
        // profileImage: URL.createObjectURL(file), 
        profile_image: URL.createObjectURL(file),
        logoShape: logoShape,       
        croppedAreaProfile: JSON.stringify(croppedImgArea),
      });
      setProfileImageIsLoading(false);
    } else {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64String = reader.result;
        setLogoImageUrl(base64String);
        setUploadCompnayLogoFile(path);
        dispatch(
          TemplateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            // companyLogo: img,
            company_logo: path?.data,
            logoShape: logoShape
          })
        );
      };      
      setFileNew(URL.createObjectURL(nFile));
      formik.setValues({
        ...formik?.values,
        // companyLogo: URL.createObjectURL(file),
        company_logo: URL.createObjectURL(file),
        logoShape: logoShape,
        croppedAreaLogo: JSON.stringify(croppedImgArea),
      });
      setCompanyLogoIsLoading(false);
    }
  };


  /**
   *
   * @param id
   * @returns void
   * @description Handle remove service button click, removes selected service from state.
   */
  const handleRemoveService = (id: any, dropDownName: string) => {
    if(dropDownName === "service1"){
      formik.setValues({
        ...formik?.values,
        service1: null,
      }); 
      selectedServiceList.splice(0,1,0) 
    }
    if(dropDownName === "service2"){
      formik.setValues({
        ...formik?.values,
        service2: null,
      }); 
      selectedServiceList.splice(1,1,0)  
    }
    if(dropDownName === "service3"){
      formik.setValues({
        ...formik?.values,
        service3: null,
      });    
      selectedServiceList.splice(2,1,0)  
    }
    if(dropDownName === "service4"){
      formik.setValues({
        ...formik?.values,
        service4: null,
      }); 
     selectedServiceList.splice(3,1,0)  
    }
    setSelectedServiceList(selectedServiceList)
  };
  
  /**
   *
   * @param e any[]
   * @description handle change event of services dropdown. Adds selected service to the state.
   * @returns void
   */

  const handleChangeServices = (e: any, dropDownName: string, index: number) => {   
    if(dropDownName === "service1"){
      const oldService = {
          id: e?.value,
          name: e?.label,
          services_id: {  
            icon: e?.svg,
            id: e?.value,
            name: e?.label,
            status: templateUserData.status,
          },
          status: templateUserData.status,
          svg: e?.svg,
        };
      if(formik?.values?.service2?.id === e.value || formik?.values?.service3?.id === e.value || formik?.values?.service4?.id === e.value){
        toast.warning("Error...! Duplicate entry not allowed.", {
            autoClose: 6000,
        });
        formik.setValues({
          ...formik?.values,
          service1: null,
        });
        }else {
        formik.setValues({
          ...formik?.values,
          service1: oldService,
        });   
        selectedServiceList.splice(index,1,oldService?.id) 
        serviceRefFocus2?.current?.focus();
      }
    }
    if(dropDownName === "service2"){     
      const oldService = {
          id: e?.value,
          name: e?.label,
          services_id: {  
            icon: e?.svg,
            id: e?.value,
            name: e?.label,
            status: templateUserData.status,
          },
          status: templateUserData.status,
          svg: e?.svg,
        };
        if(formik?.values?.service1?.id === e.value || formik?.values?.service3?.id === e.value || formik?.values?.service4?.id === e.value){
          toast.warning("Error...! Duplicate entry not allowed.", {
              autoClose: 6000,
          });
          formik.setValues({
            ...formik?.values,
            service2: null,
          });
        }else {  
          formik.setValues({
            ...formik?.values,
            service2: oldService,
          });
          selectedServiceList.splice(index,1,oldService?.id) 
          serviceRefFocus3?.current?.focus()   
        }       
    }
    if(dropDownName === "service3"){
      const oldService = {
          id: e?.value,
          name: e?.label,
          services_id: {  
            icon: e?.svg,
            id: e?.value,
            name: e?.label,
            status: templateUserData.status,
          },
          status: templateUserData.status,
          svg: e?.svg,
        };
        if(formik?.values?.service2?.id === e.value || formik?.values?.service1?.id === e.value || formik?.values?.service4?.id === e.value){
          toast.warning("Error...! Duplicate entry not allowed.", {
              autoClose: 6000,
          });
          formik.setValues({
            ...formik?.values,
            service3: null,
          });
        }else {
          formik.setValues({
            ...formik?.values,
            service3: oldService,
          });
          selectedServiceList.splice(index,1,oldService?.id) 
          serviceRefFocus4?.current?.focus()      
        }
    }
    if(dropDownName === "service4"){
      const oldService = {
          id: e?.value,
          name: e?.label,
          services_id: {  
            icon: e?.svg,
            id: e?.value,
            name: e?.label,
            status: templateUserData.status,
          },
          status: templateUserData.status,
          svg: e?.svg,
        };
        if(formik?.values?.service2?.id === e.value || formik?.values?.service3?.id === e.value || formik?.values?.service1?.id === e.value){
          toast.warning("Error...! Duplicate entry not allowed.", {
              autoClose: 6000,
          });
          formik.setValues({
            ...formik?.values,
            service4: null,
          });
        }else {
          formik.setValues({
            ...formik?.values,
            service4: oldService,
          }); 
          selectedServiceList.splice(index,1,oldService?.id)             
        }    
      }   
      setSelectedServiceList(selectedServiceList)
  };

  const pushSelectedService = () => {
    if(formik?.values?.service1){    
      selectedServiceList.splice(0,1,formik?.values?.service1?.id)   
    }
    if(formik?.values?.service2){
      selectedServiceList.splice(1,1,formik?.values?.service2?.id)      
    }
    if(formik?.values?.service3){ 
      selectedServiceList.splice(2,1,formik?.values?.service3?.id)      
    }
    if(formik?.values?.service4){ 
      selectedServiceList.splice(3,1,formik?.values?.service4?.id)      
    }
    setSelectedServiceList(selectedServiceList)
  }

  const handleSwitch = (e: any) => {
    if(e.target.value === "on"){
      formik.setValues({
        ...formik.values,
        [e.target.name]: false,
      });
    }else{
      formik.setValues({
        ...formik.values,
        [e.target.name]: true,
      });
    }
  }

  const handleLostFocusSwitch = (e : any) => {
    switch (e.target.name) {
      case "companyName":
        if(formik?.values?.companyName){
          formik.setValues({
            ...formik.values,
            isCompanyName: true,
          });
        }else {
          formik.setValues({
            ...formik.values,
            isCompanyName: false,
          });
        }
        break;
      case "websiteLink":
        if(formik?.values?.websiteLink){
          formik.setValues({
            ...formik.values,
            isWebsite: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isWebsite: false,
          });
        }
        break;    
      case "youTubeLink":
        if(formik?.values?.youTubeLink){
          formik.setValues({
            ...formik.values,
            isYouTube: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isYouTube: false,
          });
        }
        break;    
      case "whatsAppLink":
        if(formik?.values?.whatsAppLink){
          formik.setValues({
            ...formik.values,
            isWhatsapp: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isWhatsapp: false,
          });
        }
        break;    
      case "instagramLink":
        if(formik?.values?.instagramLink){
          formik.setValues({
            ...formik.values,
            isInstagram: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isInstagram: false,
          });
        }
        break;    
      case "twitterLink":
        if(formik?.values?.twitterLink){
          formik.setValues({
            ...formik.values,
            isTwitter: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isTwitter: false,
          });
        }
        break;    
      case "facebookLink":
        if(formik?.values?.facebookLink){
          formik.setValues({
            ...formik.values,
            isFacebook: true,
          });
        }else{
          formik.setValues({
            ...formik.values,
            isFacebook: false,
          });
        }
        break;    
      default:
        break;
    }
  }

  useEffect(() => {
    if (!whatsAppLinkEdited) {
      if(formik?.values?.mobileNumber! || templateUserData?.mobileNumber){
        formik.values!.whatsAppLink = formik?.values?.mobileNumber! || templateUserData?.mobileNumber;
        formik.touched!.whatsAppLink = true;
      }
    }
    if(templateUserData?.templateSecondaryColor){
      root?.style.setProperty("--secondary05", templateUserData?.templateSecondaryColor || "#f4f7fc");
    }
  }, [formik.values?.mobileNumber, formik.values, whatsAppLinkEdited, root?.style, templateUserData?.mobileNumber, templateUserData?.templateSecondaryColor, formik.touched]);

  const handleCircleClickColor = (val: any) => {
    let color = val?.hex ? val?.hex : "#234170";   
    root?.style.setProperty("--theme05", color);
    colors.forEach(element => {
      if((element?.color).toLowerCase() === val?.hex){
        secondaryColor = element?.lightColor;
        root?.style.setProperty("--secondary05", element?.lightColor);
      }
    });
    dispatch(
      templateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData,
        templateColor: color,
        templateSecondaryColor: secondaryColor!,
      })
    );
    formik.setValues({
      ...formik.values,
      templateColor: color,
      templateSecondaryColor: secondaryColor
    })
  }

  const handleColorPicker = () => {
    setShowColorPickerModal(!showColorPickerModal)
  }

  return (
    <>
      <main className="main ">
        <div className="edit-temp-page" style={{ marginTop: "25px" }}>
          <div className="container">
            <form
              className="edit-temp-wrap"
              onSubmit={(e) => {
                e.preventDefault();
                if (formik?.values?.whatsAppLink === null) {
                  formik.setValues({
                    ...formik.values,
                    whatsAppLink: formik.values.mobileNumber,
                  });
                }
                formik.handleSubmit();
              }}
              noValidate
            >
              {showCropperFor === "profileImage" ? <CropProfileImage 
                   show={showCroper}
                   src={src}
                   setSrc={setSrc}
                   setPreview={setPreview}
                   preview={preview}
                   setModalOpen={setShowCroper}
                   setProfileImageIsLoading={setProfileImageIsLoading}
                   uploadProfileImageFile={uploadProfileImageFile}
                   setChangeImageEvent={setChangeImageEvent}
                   file={file}
                  //  existProfileImage={templateUserData?.profileImage}
                  //  cropperProfileImageInput={templateUserData?.profileImage}   
                   existProfileImage={templateUserData?.profile_image?.filename_disk}
                   cropperProfileImageInput={templateUserData?.profile_image?.filename_disk}   
                   existProfileImageName={templateUserData?.profile_image?.filename_download}
                   onCropped={onImageChange}
              /> :               
              <CropCompanyLogo 
                   show={showCroper}
                   src={src1}
                   setSrc1={setSrc1}
                  //  setPreview={setPreview}
                  //  preview={preview}
                   setModalOpen={setShowCroper}
                   companyLogoIsLoading={companyLogoIsLoading}
                   setCompanyLogoIsLoading={setCompanyLogoIsLoading}
                   uploadCompanyLogoFile={uploadCompanyLogoFile}
                   setChangeImageEvent={setChangeImageEvent}
                   file={file1}
                   setLogoShape={setLogoShape}
                   logoShape={logoShape}
                   loader={loader}
                   setLoader={setLoader}
                   existLogoShape={templateUserData?.logoShape}
                  //  existCompanyImage={templateUserData?.companyLogo}
                  //  cropperCompanyImageInput={templateUserData?.companyLogo}   
                   existCompanyImage={templateUserData?.company_logo?.filename_disk}
                   cropperCompanyImageInput={templateUserData?.company_logo?.filename_disk}   
                   existCompanyImageName={templateUserData?.company_logo?.filename_download}
                   onCropped={onImageChange}
              />             
            }
              <div className="row">
                <div className="col-lg-8">
                  <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="field-sections  field-label">
                          <div className="label-20">Personal information</div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="mt-4 p-0">
                        <div className="card-bordered">
                          <div className="row mb-lg-4">
                            <div className="col-lg-4 upload-side d-flex flex-column mb-lg-0 mb-4">
                              <div className="upload-box">
                                <UploadFileNew
                                  name="profileImage"
                                  className="file-upload"
                                  id="uploaderProfile"
                                  onChange={(e: any) => handleImgChange(e, "profileImage")}
                                  title="Upload Profile Image"
                                  isTouched={
                                    formik.touched.profile_image &&
                                    formik.errors.profile_image
                                      ? true
                                      : false
                                  }
                                  errorMsg={
                                    formik.errors.profile_image
                                      ? "Profile Image Required"
                                      : ""
                                  }
                                  show={showCroper}
                                  setShowCropper={setShowCroper}
                                  showCropperFor={"profileImage"}
                                  isLoading={profileImageIsLoading}
                                  setIsLoading={setProfileImageIsLoading}
                                  setShowCropperFor={setShowCropperFor}
                                  changeEvent={changeEvent}
                                  setChangeEvent={setChangeImageEvent}
                                  profileFile={formik?.values?.profile_image}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8">

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="floating-group ">
                                    <InputField
                                      type="text"
                                      className="mb-3"
                                      placeholder=" "
                                      name="firstName"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      onFocus={formik.handleBlur}
                                      isTouched={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                          ? true
                                          : false
                                      }
                                      errorMsg={
                                        formik.errors.firstName
                                          ? formik.errors.firstName
                                          : ""
                                      }
                                      id="firstName"
                                      value={
                                        formik?.values?.firstName
                                          ? formik?.values?.firstName
                                          : ""
                                      }
                                      isDisabled={false}
                                      autoFocus={false}
                                    />
                                    <label className="float-label">
                                      First Name
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="floating-group">
                                    <InputField
                                      type="text"
                                      className="mb-3"
                                      placeholder=" "
                                      name="lastName"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      onFocus={formik.handleBlur}
                                      isTouched={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                          ? true
                                          : false
                                      }
                                      errorMsg={
                                        formik.errors.lastName
                                          ? formik.errors.lastName
                                          : ""
                                      }
                                      id="lastName"
                                      value={formik?.values?.lastName}
                                      isDisabled={false}
                                      autoFocus={false}
                                    />
                                    <label className="float-label">
                                      Last Name
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="floating-group">
                                    <InputField
                                      type="text"
                                      className="mb-3"
                                      placeholder=" "
                                      name="designation"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      onFocus={formik.handleBlur}
                                      isTouched={
                                        formik.touched.designation &&
                                        formik.errors.designation
                                          ? true
                                          : false
                                      }
                                      errorMsg={
                                        formik.errors.designation
                                          ? formik.errors.designation
                                          : ""
                                      }
                                      id="designation"
                                      value={formik?.values?.designation!}
                                      isDisabled={false}
                                      autoFocus={false}
                                    />
                                    <label className="float-label">
                                      Designation
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="floating-group disabled">
                                    <InputField
                                      type="tel"
                                      className="mb-3"
                                      placeholder=" "
                                      name="mobileNumber"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      onFocus={formik.handleBlur}
                                      isTouched={
                                        formik.touched.mobileNumber &&
                                        formik.errors.mobileNumber
                                          ? true
                                          : false
                                      }
                                      errorMsg={
                                        formik.errors.mobileNumber
                                          ? formik.errors.mobileNumber
                                          : ""
                                      }
                                      id="mobileNumber"
                                      value={formik?.values?.mobileNumber!}
                                      isDisabled={
                                        formik?.values?.mobileNumber
                                          ? true
                                          : false
                                      }
                                      autoFocus={false}
                                    />
                                    <label className="float-label">
                                      Contact No.
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="floating-group ">
                                <InputField
                                  type="email"
                                  className="mb-3"
                                  placeholder=" "
                                  name="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  onFocus={formik.handleBlur}
                                  isTouched={
                                    formik.touched.email && formik.errors.email
                                      ? true
                                      : false
                                  }
                                  errorMsg={
                                    formik.errors.email
                                      ? formik.errors.email
                                      : ""
                                  }
                                  value={formik?.values?.email}
                                  isDisabled={false}
                                  autoFocus={false}
                                />
                                <label className="float-label">Email</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="posiion-relative inside-switch">
                                <div className="floating-group ">
                                  <InputField
                                    type="text"
                                    className="mb-3"
                                    placeholder=" "
                                    name="websiteLink"
                                    onChange={formik.handleChange}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      handleLostFocusSwitch(e);
                                    }}
                                    onFocus={formik.handleBlur}
                                    isTouched={
                                      formik.touched.websiteLink &&
                                      formik.errors.websiteLink
                                        ? true
                                        : false
                                    }
                                    errorMsg={
                                      formik.errors.websiteLink
                                        ? formik.errors.websiteLink
                                        : ""
                                    }
                                    id="websiteLink"
                                    value={formik?.values?.websiteLink}
                                    isDisabled={false}
                                    autoFocus={false}
                                  />
                                  <label className="float-label">
                                    {formik.values?.isWebsite
                                      ? "* Website"
                                      : "Website"}
                                  </label>
                                </div>
                                <Switch
                                  checked={formik?.values?.isWebsite}
                                  htmlFor={`isWebsite`}
                                  id={`isWebsite`}
                                  name={`isWebsite`}
                                  onChange={(e) => handleSwitch(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="floating-group mb-0">
                            <InputField
                              type="text"
                              className="mb-3"
                              placeholder=" "
                              name="address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onFocus={formik.handleBlur}
                              value={formik?.values?.address}
                              isTouched={
                                formik.touched.address && formik.errors.address
                                  ? true
                                  : false
                              }
                              errorMsg={
                                formik.errors.address
                                  ? formik.errors.address
                                  : ""
                              }
                              autoFocus={false}
                            />
                            <label className="float-label">Address</label>
                          </div>
                          <div className="floating-group  mb-0">
                            <InputField
                              type="text"
                              className="mb-3"
                              placeholder=" "
                              name="description"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onFocus={formik.handleBlur}
                              value={formik?.values?.description}
                              isTouched={
                                formik.touched.description &&
                                formik.errors.description
                                  ? true
                                  : false
                              }
                              errorMsg={
                                formik.errors.description
                                  ? formik.errors.description
                                  : ""
                              }
                              autoFocus={false}
                            />
                            <label className="float-label">About Me</label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Company information start*/}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="field-sections field-label">
                          <div className="label-20">Company information</div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="mt-4 p-0">
                        <div className="card-bordered">
                          <div className="row">
                            <div className="col-lg-4 d-flex flex-column">
                              <div className="upload-logo-wrap d-flex mb-4">
                                <div
                                  className={`upload-box ${
                                    templateUserData?.logoShape === "circle"
                                      ? "logo-ractangle"
                                      : "logo-square"
                                  }`}
                                >
                                  <UploadLogoFile
                                    name="companyLogo"
                                    className="file-upload"
                                    id="uploaderCompanyLogo"
                                    onChange={(e: any) => handleImgChange(e, "companyLogo")}
                                    title="Upload Company Logo"
                                    isTouched={
                                      formik.touched.company_logo &&
                                      formik.errors.company_logo
                                        ? true
                                        : false
                                    }
                                    errorMsg={
                                      formik.errors.company_logo
                                        ? "Compnay Logo Required"
                                        : ""
                                    }
                                    show={showCroper}
                                    setShowCropper={setShowCroper}
                                    showCropperFor={"companyLogo"}
                                    isLoading={companyLogoIsLoading}
                                    setIsLoading={setCompanyLogoIsLoading}
                                    setShowCropperFor={setShowCropperFor}
                                    changeEvent={changeEvent}
                                    setChangeEvent={setChangeImageEvent}
                                    companyLogoFile={
                                      formik?.values?.company_logo
                                    }
                                  />
                                </div>
                              </div>
                              <div className="upload-switch mb-4 mb-lg-0">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="label mb-0 me-4 pe-2">
                                    Switch to show & hide image
                                  </div>
                                  <Switch
                                    checked={formik?.values?.isCompanyLogo}
                                    htmlFor={`isCompanyLogo`}
                                    id={`isCompanyLogo`}
                                    name={`isCompanyLogo`}
                                    onChange={(e) => handleSwitch(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="posiion-relative inside-switch mt-3">
                                <div className="floating-group ">
                                  <InputField
                                    type="text"
                                    className="mb-3"
                                    placeholder=" "
                                    name="companyName"
                                    onChange={formik.handleChange}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      handleLostFocusSwitch(e);
                                    }}
                                    onFocus={formik.handleBlur}
                                    value={formik?.values?.companyName}
                                    isTouched={
                                      formik.touched.companyName &&
                                      formik.errors.companyName
                                        ? true
                                        : false
                                    }
                                    errorMsg={
                                      formik.errors.companyName
                                        ? formik.errors.companyName
                                        : ""
                                    }
                                    autoFocus={false}
                                  />
                                  <label className="float-label">
                                    Company Name
                                  </label>
                                </div>
                                <Switch
                                  checked={formik?.values?.isCompanyName}
                                  htmlFor={`isCompanyName`}
                                  id={`isCompanyName`}
                                  name={`isCompanyName`}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    handleSwitch(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Company information end*/}
                    <Accordion.Item eventKey="2" className="services-box">
                      <Accordion.Header>
                        <div className="d-flex align-items-center flex-wrap field-label">
                          <div className="label-20 me-3">Services</div>
                          <div className="select-note">
                            ( Select at least 1 )
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="mt-4 p-0">
                        <div className="card-bordered">
                          <div className="services-fields">
                            <div className="row">
                              <div className="col-lg-6 d-flex justify-content-between">
                                <SelectDropdown
                                  placeholder="Select a Service / Items to Add"
                                  onChange={(e: any) => {
                                    handleChangeServices(e, "service1", 0);
                                  }}
                                  onBlur={formik.handleBlur}
                                  onFocus={formik.handleBlur}
                                  handleRemove={(e: any) => {
                                    handleRemoveService(
                                      formik?.values?.service1?.id,
                                      "service1"
                                    );
                                  }}
                                  name="service1"
                                  id="service1"
                                  value={formik?.values?.service1}
                                  data={serviceList}
                                  selectedServiceList={selectedServiceList}
                                  reference={serviceRefFocus1}
                                />
                              </div>
                              <div className="col-lg-6 d-flex justify-content-between">
                                <SelectDropdown
                                  placeholder="Select a Service / Items to Add"
                                  onChange={(e: any) =>
                                    handleChangeServices(e, "service2", 1)
                                  }
                                  onBlur={formik.handleBlur}
                                  onFocus={formik.handleBlur}
                                  handleRemove={() => {
                                    handleRemoveService(
                                      formik?.values?.service2?.id,
                                      "service2"
                                    );
                                  }}
                                  name="service2"
                                  id="service2"
                                  value={formik?.values?.service2}
                                  data={serviceList}
                                  selectedServiceList={selectedServiceList}
                                  reference={serviceRefFocus2}
                                />
                              </div>
                              <div className="col-lg-6 d-flex justify-content-between">
                                <SelectDropdown
                                  placeholder="Select a Service / Items to Add"
                                  onChange={(e: any) =>
                                    handleChangeServices(e, "service3", 2)
                                  }
                                  onBlur={formik.handleBlur}
                                  onFocus={formik.handleBlur}
                                  handleRemove={() => {
                                    handleRemoveService(
                                      formik?.values?.service3?.id,
                                      "service3"
                                    );
                                  }}
                                  name="service3"
                                  id="service3"
                                  value={formik?.values?.service3}
                                  data={serviceList}
                                  selectedServiceList={selectedServiceList}
                                  reference={serviceRefFocus3}
                                />
                              </div>
                              <div className="col-lg-6 d-flex justify-content-between">
                                <SelectDropdown
                                  placeholder="Select a Service / Items to Add"
                                  onChange={(e: any) =>
                                    handleChangeServices(e, "service4", 3)
                                  }
                                  onBlur={formik.handleBlur}
                                  onFocus={formik.handleBlur}
                                  handleRemove={(e: any) => {
                                    handleRemoveService(
                                      formik?.values?.service4?.id,
                                      "service4"
                                    );
                                  }}
                                  name="service4"
                                  id="service4"
                                  value={formik?.values?.service4}
                                  data={serviceList}
                                  selectedServiceList={selectedServiceList}
                                  reference={serviceRefFocus4}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* Socila media section */}

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="label-20  field-label">
                          Social Media Links
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="mt-4 p-0">
                        {/*  Social Media Links here */}
                        <div className="card-bordered">
                          <div className="row">
                            <div className="col-md-6">
                              <SocialMedia
                                type={'number'}
                                svg={whatsapp02}
                                placeHolder="* WhatsApp Number"
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  handleLostFocusSwitch(e);
                                }}
                                onFocus={formik.handleBlur}
                                onLinkInput={(e) => {
                                  formik.handleChange(e);
                                  setWhatsAppLinkEdited(true);
                                }}
                                onChange={(e) => handleSwitch(e)}
                                checked={
                                  formik?.values?.isWhatsapp ? true : false
                                }
                                switchName="isWhatsapp"
                                switchHtmlFor="isWhatsapp"
                                switchId="isWhatsapp"
                                name="whatsAppLink"
                                id="whatsAppLink"
                                isTouched={
                                  formik.touched.mobileNumber ? true : false
                                }
                                errorMsg={
                                  formik.errors.whatsAppLink
                                    ? formik.errors.whatsAppLink
                                    : ""
                                }
                                value={
                                  formik?.values?.whatsAppLink!
                                    ? formik?.values?.whatsAppLink!
                                    : whatsAppLinkEdited
                                    ? formik.values.whatsAppLink!
                                    : formik?.values?.mobileNumber!
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <SocialMedia
                                svg={youtube02}
                                id="youTubeLink"
                                name="youTubeLink"
                                placeHolder={
                                  formik.values?.isYouTube
                                    ? "* YouTube url"
                                    : "YouTube url"
                                }
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  handleLostFocusSwitch(e);
                                }}
                                onFocus={formik.handleBlur}
                                onLinkInput={(e) => formik.handleChange(e)}
                                onChange={(e) => handleSwitch(e)}
                                checked={formik?.values?.isYouTube}
                                switchName="isYouTube"
                                switchHtmlFor="isYouTube"
                                switchId="isYouTube"
                                value={formik?.values?.youTubeLink!}
                                isTouched={
                                  formik.touched.youTubeLink &&
                                  formik?.values?.isYouTube && formik.errors.youTubeLink 
                                    ? true
                                    : false
                                }
                                errorMsg={
                                  formik?.values?.isYouTube && formik.errors.youTubeLink 
                                    ? formik.errors.youTubeLink
                                    : ""
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <SocialMedia
                                svg={instagram02}
                                id="instagramLink"
                                name="instagramLink"
                                placeHolder={
                                  formik.values?.isInstagram
                                    ? "* Instagram url"
                                    : " Instagram url"
                                }
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  handleLostFocusSwitch(e);
                                }}
                                onFocus={formik.handleBlur}
                                checked={formik?.values?.isInstagram}
                                onLinkInput={(e) => formik.handleChange(e)}
                                onChange={(e) => handleSwitch(e)}
                                switchHtmlFor="isInstagram"
                                switchId="isInstagram"
                                switchName="isInstagram"
                                isSwitch={true}
                                value={formik?.values?.instagramLink!}
                                isTouched={
                                  formik.touched.instagramLink &&
                                  formik?.values?.isInstagram && formik.errors.instagramLink
                                    ? true
                                    : false
                                }
                                errorMsg={
                                  formik?.values?.isInstagram && formik.errors.instagramLink
                                    ? formik.errors.instagramLink
                                    : ""
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <SocialMedia
                                svg={twitter02}
                                id="twitterLink"
                                name="twitterLink"
                                placeHolder={
                                  formik.values?.isTwitter
                                    ? "*  Twitter url"
                                    : " Twitter url"
                                }
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  handleLostFocusSwitch(e);
                                }}
                                onFocus={formik.handleBlur}
                                checked={formik?.values?.isTwitter}
                                onLinkInput={(e) => formik.handleChange(e)}
                                onChange={(e) => handleSwitch(e)}
                                switchName="isTwitter"
                                switchHtmlFor="isTwitter"
                                switchId="isTwitter"
                                isSwitch={true}
                                value={formik?.values?.twitterLink!}
                                isTouched={
                                  formik.touched.twitterLink &&
                                  formik?.values?.isTwitter && formik.errors.twitterLink
                                    ? true
                                    : false
                                }
                                errorMsg={
                                  formik?.values?.isTwitter && formik.errors.twitterLink
                                    ? formik.errors.twitterLink
                                    : ""
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <SocialMedia
                                svg={facebook02}
                                id="facebookLink"
                                name="facebookLink"
                                placeHolder={
                                  formik.values?.isFacebook
                                    ? "* Facebook url"
                                    : " Facebook url"
                                }
                                value={formik?.values?.facebookLink!}
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  handleLostFocusSwitch(e);
                                }}
                                onFocus={formik.handleBlur}
                                checked={formik?.values?.isFacebook}
                                onLinkInput={(e) => formik.handleChange(e)}
                                onChange={(e) => handleSwitch(e)}
                                switchName="isFacebook"
                                switchHtmlFor="isFacebook"
                                switchId="isFacebook"
                                isSwitch={true}
                                isTouched={
                                  formik.touched.facebookLink &&
                                  formik?.values?.isFacebook && formik.errors.facebookLink
                                    ? true
                                    : false
                                }
                                errorMsg={
                                  formik?.values?.isFacebook && formik.errors.facebookLink
                                    ? formik.errors.facebookLink
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* right section */}
                <div className="col-lg-4 mb-3 d-flex flex-column sidebar-card">
                  <div className="edit-right-col">
                    {width < 992 ? (
                      <></>
                    ) : (
                      <>
                        <div className="temp-topsection d-flex justify-content-between align-items-center mb-3">
                          <div className="label d-flex align-items-center">
                            <span>Color</span>
                            <span
                              className="d-flex ms-4 color-handle"
                              onClick={handleColorPicker}
                            ></span>
                          </div>
                          <TempPrevNext
                            handleNextClick={handleNextClick}
                            handlePreviousClick={handlePreviousClick}
                            templateList={templateList}
                            currentTemplate={currentTemplateNumber}
                          />
                        </div>
                        {showColorPickerModal ? (
                          <div className="color-plates">
                            <button
                              className="btn color-close"
                              type="button"
                              onClick={handleColorPicker}
                            >
                              <SVG src={close} />
                            </button>
                            <CirclePicker
                              className="w-100"
                              colors={colors?.map((col: any) => col?.color)}
                              circleSize={20}
                              onChangeComplete={(color: any) =>
                                handleCircleClickColor(color)
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          {templateId === 6 && (
                            <Template06
                              profilePreview={formik?.values?.profile_image?.filename_disk}
                              companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                              loader={companyLogoIsLoading}
                              logoChangeLoading={loader}
                            />
                          )}
                          {templateId === 7 && (
                            <Template07
                              profilePreview={formik?.values?.profile_image?.filename_disk}
                              companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                              loader={companyLogoIsLoading}
                              logoChangeLoading={loader}
                            />
                          )}
                          {templateId === 8 && (
                            <Template08
                              profilePreview={formik?.values?.profile_image?.filename_disk}
                              companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                              loader={companyLogoIsLoading}
                              logoChangeLoading={loader}
                            />
                          )}
                          {templateId === 9 && (
                            <Template09
                              profilePreview={formik?.values?.profile_image?.filename_disk}
                              companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                              loader={companyLogoIsLoading}
                              logoChangeLoading={loader}
                            />
                          )}
                          {templateId === 10 && (
                            <Template010
                              profilePreview={formik?.values?.profile_image?.filename_disk}
                              companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                              loader={companyLogoIsLoading}
                              logoChangeLoading={loader}
                            />
                          )}
                        </div>
                      </>
                    )}
                    <div className="button-group d-flex w-100">
                      <button
                        className="btn btn-primary preview-btn w-100 mt-lg-4 mt-0 me-2"
                        type="button"
                        onClick={handleShow}
                      >
                        Preview
                      </button>

                      <Buttons
                        btnText={!loading ? "Save" : "Please Wait"}
                        buttonName={"button"}
                        type="submit"
                        className="w-100 mt-lg-4 mt-0 ms-lg-0 ms-2"
                        onClick={() => {
                          const err = Object.keys(formik.errors);
                          if (err.length) {
                            const input = document.querySelector(
                              `input[name=${err[0]}]`
                            );
                            if (input) {
                              input.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "start",
                              });
                            }
                          }
                        }}
                        disabled={loading || companyLogoIsLoading || profileImageIsLoading ? true : false}
                      ></Buttons>
                    </div>
                  </div>
                  {loading ? <Loader /> : <></>}
                </div>
              </div>
            </form>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Profile Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {templateId === 6 && (
                    <Template06
                      profilePreview={formik?.values?.profile_image?.filename_disk}
                      companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                      loader={companyLogoIsLoading}
                      logoChangeLoading={loader}
                    />
                  )}
                  {templateId === 7 && (
                    <Template07
                      profilePreview={formik?.values?.profile_image?.filename_disk}
                      companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                      loader={companyLogoIsLoading}
                      logoChangeLoading={loader}
                    />
                  )}
                  {templateId === 8 && (
                    <Template08
                      profilePreview={formik?.values?.profile_image?.filename_disk}
                      companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                      loader={companyLogoIsLoading}
                      logoChangeLoading={loader}
                    />
                  )}
                  {templateId === 9 && (
                    <Template09
                      profilePreview={formik?.values?.profile_image?.filename_disk}
                      companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                      loader={companyLogoIsLoading}
                      logoChangeLoading={loader}
                    />
                  )}
                  {templateId === 10 && (
                    <Template010
                      profilePreview={formik?.values?.profile_image?.filename_disk}
                      companyLogoPreview={formik?.values?.company_logo?.filename_disk}
                      loader={companyLogoIsLoading}
                      logoChangeLoading={loader}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
              <div className="button-group d-flex w-100">
                      <button
                        className="btn btn-primary preview-btn w-100 mt-lg-4 mt-0 me-2"
                        type="button"
                        onClick={handleClose}
                      >
                        Back
                      </button>

                      <Buttons
                        btnText={!loading ? "Save" : "Please Wait"}
                        buttonName={"button"}
                        type="submit"
                        className="w-100 mt-lg-4 mt-0 ms-lg-0 ms-2"
                        onClick={(e) => {
                          const err = Object.keys(formik.errors);
                          if (err.length) {
                            const input = document.querySelector(
                              `input[name=${err[0]}]`
                            );
                            if (input) {
                              input.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "start",
                              });
                            }
                          }
                          if(formik?.errors){
                            setShow(false)
                          }
                          e.preventDefault();
                          if (formik?.values?.whatsAppLink === null) {
                            formik.setValues({
                              ...formik.values,
                              whatsAppLink: formik.values.mobileNumber,
                            });
                          }
                          formik.handleSubmit();
                        }}
                        disabled={loading || companyLogoIsLoading || profileImageIsLoading ? true : false}
                      ></Buttons>
                    </div>                
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </main>
    </>
  );
};
export default EditTemplateNew;
