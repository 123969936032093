import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SVG from "react-inlinesvg";
import Buttons from "./Buttons";
import "../scss/header.scss";
import { useLocation } from "react-router-dom";
import { RootState } from "../setup";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import preview from "../templates/images/icons/eye.svg";
import userIcon from "../templates/images/icons/user-icon.svg";
import logout from "../templates/images/icons/logout.svg";
import { Avatar } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link"; 
import { ASSEST_URL } from "../app/helper/EnvVar";
function Navigation() {
  const navRef = React.useRef<any>();
  const location = useLocation();
  const onToggleClick = () => {
    navRef.current.classList.toggle("show-nav");
  };
  const isAuthorized = useSelector(
    (state: RootState) => state.auth.isAuthorized
  );
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  ); 
  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Link to="/home" className="navbar-brand">
          <SVG src="./assets/images/logo.svg" className="logo-main" />
        </Link>
        <button
          type="button"
          className="btn btn-nav p-0"
          onClick={onToggleClick}
        >
          <div className="menu_icon">
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
          </div>
        </button>

        <Navbar.Collapse id="basic-navbar-nav" ref={navRef}>
          <Nav className="ms-lg-auto d-flex align-items-center">
            <div className="d-block d-lg-none position-relative w-100 text-center mb-4">
              <button
                type="button"
                className="btn btn-nav p-0 side-close-btn"
                onClick={onToggleClick}
              >
                <div className="close-icon">
                  <SVG
                    src="./assets/images/icons/close.svg"
                    className="close"
                  />
                </div>
              </button>
              <Nav.Link href="/home" className="pt-0">
                <SVG src="./assets/images/logo.svg" className="logo-main" />
              </Nav.Link>
            </div>
            <Link to={location?.pathname !== "/home" ? `/home#home` : "#home"}>
              <span className="nav-link" onClick={onToggleClick}>
                Home
              </span>
            </Link>
            <Link
              to={location?.pathname !== "/home" ? `/home#steps` : "#steps"} 
            >
              <span className="nav-link" onClick={onToggleClick}>
                Steps
              </span>
            </Link>
            <Link
              to={location?.pathname !== "/home" ? `/home#video` : "#video"}
            >
              <span onClick={onToggleClick} className="nav-link">
                How to
              </span>
            </Link>
            <Link
              to={
                location?.pathname !== "/home"
                  ? `/home#business-cards`
                  : "#business-cards"
              }
            >
              <span onClick={onToggleClick} className="nav-link">
                Cards
              </span>
            </Link>
            <Link
              to={
                location?.pathname !== "/home" ? `/home#contacts` : "#contacts"
              }
            >
              <span onClick={onToggleClick} className="nav-link">
                Contact
              </span>
            </Link>
          
            {isAuthorized ? (
              <div className="btn-groups drop-items ">
                <Dropdown
                  as={ButtonGroup}
                  className="align-items-center d-none d-lg-flex"
                >
                  <div className="img-wrap mx-auto">
                    <div className=" position-relative me-1">
                      <Avatar
                        src={`${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}`}
                        style={{ width: "34px", height: "34px" }}
                      />
                    </div>
                  </div>

                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                    className="p-0"
                  />

                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item
                      className="user-p d-flex p-0"
                      onClick={onToggleClick}
                    >
                      <Link
                        to="/profile/preview "
                        className="px-3 py-2 d-flex align-items-center w-100"
                      >
                        <span className="me-3">
                          <SVG src={preview} />
                        </span>
                        <span>Profile Preview</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="user-p d-flex p-0"
                      onClick={onToggleClick}
                    >
                      <Link
                        to="/profile/user-profile"
                        className="px-3 py-2 d-flex align-items-center w-100"
                      >
                        <span className="me-3">
                          <SVG src={userIcon} />
                        </span>
                        <span> User Profile</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="lo lg-out-btn d-none d-lg-block px-3 py-2"
                      href="/logout"
                    >
                      <span className="me-3">
                        <SVG src={logout} />
                      </span>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link
                  to="/profile/preview"
                  className=" d-block d-lg-none mobile-link"
                >
                  Profile Preview
                </Link>
                <Link
                  to="/profile/user-profile"
                  className=" d-block d-lg-none mobile-link"
                >
                  User Profile
                </Link>
                <Link to="/logout">
                  <Buttons
                    btnText={"Logout"}
                    buttonName={"button"}
                    className="d-block d-lg-none"
                  ></Buttons>
                </Link>
              </div>
            ) : (
              <div className="btn-groups ">
                <Link to="/auth/login">
                  <Buttons
                    btnText={"Login"}
                    buttonName={"button"}
                    className="btn btn-primary me-lg-3"
                  ></Buttons>
                </Link>
                <Link to="/auth/login">
                  <Buttons
                    btnText={"Signup"}
                    buttonName={"button"}
                    className="btn btn-primary btn-reverse"
                  ></Buttons>
                </Link>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
