import "../../templates/scss/temp04.scss"; 
import SVG from "react-inlinesvg";
import user01 from "../images/user01.png";
import call from "../images/icons/call.svg";
import map from "../images/icons/map.svg";
import logo from "../images/logo.svg";
import envelop from "../images/icons/envelop.svg";
import location from "../images/icons/location.svg";
import whatsapp from "../images/icons/whatsapp.svg";
import instagram from "../images/icons/instagram.svg";
import youtube from "../images/icons/youtube.svg";
import twitter from "../images/icons/twitter.svg";
import facebook from "../images/icons/facebook.svg";
import shareNew from "../images/icons/shareNew.svg";
import reviewNew from "../images/icons/reviewNew.svg";
import curveImg from "../images/curve-bottom.svg";
import pocketlogo from "../images/pocketsmall.svg"; 
import contactadd from "../images/icons/contactadd.svg";
import loading from "../images/misc/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { normalizeURL, parseUrl, socialSharing } from "../../app/helper/helper";
import { useEffect, useState } from "react";
import { TemplateUserDataRedux } from "../../app/modules/template/redux/ReduxDispatchExport";
import { ASSEST_URL, PUBLIC_URL } from "../../app/helper/EnvVar";
import { ReviewModal } from "../../app/pages/templates/components/Templates/common/ReviewModal";
import { SocialSharingModal } from "../../app/helper/SocialSharingModal";
import { Avatar } from "@material-ui/core"; 
import { QRCodeSVG } from "qrcode.react";
import { downloadVCFFile } from "../../downloadVCF";

type Props = {
  profilePreview: any,
  companyLogoPreview: any,
  loader?: boolean,
  logoChangeLoading?: boolean
}

const Template08 = ({ profilePreview, companyLogoPreview, loader, logoChangeLoading} : Props) => {
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  const auth = useSelector(
    (state: RootState) => state.auth.isAuthorized!
  );
  const dispatch = useDispatch();
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  
  useEffect(() => {
    dispatch(
      TemplateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData!,
        templateColor: templateUserData?.templateColor || "#c45f1c",
      })
    );
    setColor(templateUserData?.templateColor || "#c45f1c");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUserData?.selectedTemplateID, dispatch]);

  function setColor(newColor: any) {
    let color = newColor ? newColor : "#c45f1c"
    document.documentElement.style.setProperty("--theme05", color);
    document.documentElement.style.setProperty(
      "--secondary05",
      templateUserData?.templateSecondaryColor || "#fef8f5"
    );
  }

  const showReviewModal = () => {
    setReviewModal(!reviewModal);
  };

  const handleClose = () => {
    setShowFallback(!showFallback);
  };

  const shareButton = () => {
    socialSharing(templateUserData, () => {
      setShowFallback(true);
    });
  };

  return (
    <>
      <div className="template-main">
        <div className="temp-inner overflow-hidden">
          <div className="temp-header p-0 curve-header">
            {templateUserData?.isCompanyLogo ? (
              templateUserData?.company_logo ? (
                <>
                {loader ? <Avatar 
                  src={loading}
                  alt="loding"
                  variant="square"
                  style={{ width: 70, height: 70 }}
                  className="ps-3 pe-3 pb-4 pt-4"
                  />
                : <span
                  className={`logo d-flex ${
                    templateUserData?.logoShape === "circle"
                      ? ""
                      : "ps-3 pe-3 pb-4 pt-4"
                  }`}
                >
                  {templateUserData?.logoShape === "circle" ? (
                    <>
                    {logoChangeLoading ? <Avatar 
                      src={loading}
                      alt="loding"
                      variant="square"
                      style={{ width: 70, height: 70 }}
                      className="ps-3 pe-3 pb-4 pt-4"
                      /> : <img
                      src={
                        `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                          ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                          : companyLogoPreview
                          ? companyLogoPreview
                          : logo
                      }
                      alt="logo"
                    />}
                    </>
                  ) : (
                    <>
                    {loader ? <Avatar 
                    src={loading}
                    alt="loding"
                    variant="square"
                    style={{ width: 70, height: 70 }}
                    className="ps-3 pe-3 pb-4 pt-4"
                    />
                  :
                  <>  
                  {logoChangeLoading ? <Avatar 
                    src={loading}
                    alt="loding"
                    variant="square"
                    style={{ width: 50, height: 50 }}
                    /> : <Avatar
                      src={
                        `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                          ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                          : companyLogoPreview
                          ? companyLogoPreview
                          : logo
                      }
                      variant="square"
                      alt="logo"
                    />}
                    </>
                  }
                  </>
                  )}
                </span>}
                </>
              ) : (
                <>
                {loader ? <Avatar 
                  src={loading}
                  alt="loding"
                  variant="square"
                  style={{ width: 70, height: 70 }}
                  className="ps-3 pe-3 pb-4 pt-4"
                  />
                : <span className="logo p-4 d-flex justify-content-center">
                  <Avatar
                  src={
                    `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                      ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                      : companyLogoPreview
                      ? companyLogoPreview
                      : logo
                  }
                    variant="square"
                    alt="logo"
                    style={{ width: "190px", height: "30px" }}
                  />
                </span>}
                </>
              )
            ) : (
              " "
            )}
            <div className="user-avtar-round position-relative">
              <div className="user-img rounded mx-auto">
                  {templateUserData?.profile_image ? (
                    <Avatar
                      src={
                        `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}`
                          ? `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}`
                          : profilePreview
                      }
                      variant="circular"
                      alt="user-img"
                      style={{ width: "130px", height: "130px" }}
                    />
                  ) : (
                    <Avatar
                      src={user01}
                      variant="circular"
                      alt="user-img"
                      style={{ width: "130px", height: "130px" }}
                    />
                  )
                }
              </div>
              <div className="curve-img-bottom">
                <SVG
                  src={curveImg}
                  // fill={`${
                  //   templateUserData?.templateSecondaryColor || "#fef8f5"
                  // }`}
                />
              </div>
            </div>
            <div className="card-inner text-center">
              <div className="name-cols4 pe-0 text-center">
                <div className="user-name">
                  {templateUserData?.firstName || "John"}{" "}
                  {templateUserData?.lastName || "Doe"}
                </div>
                <div className="user-post  mb-2">
                  {templateUserData?.designation || `Co-founder `}
                </div>
                <div className="c-name">
                  {templateUserData?.isCompanyName
                    ? templateUserData?.companyName || "Infinity Aotumation"
                    : ""}
                </div>
              </div>
            </div>
          </div>

          <div className="temp-lower">
            <ul className="list-unstyled d-flex service-list">
              {templateUserData?.service1?.name ? (
                <li className="item" key={templateUserData?.service1?.id}>
                  <div className="ser-link">
                    <div className="icon">
                      <SVG
                        src={`${ASSEST_URL}/${templateUserData?.service1?.svg?.filename_disk}`}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                        style={{ height: "23.434", width: "26.071" }}
                      />
                    </div>
                    <span className="service-name">
                      {templateUserData?.service1?.name}
                    </span>
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service2?.name ? (
                <li className="item" key={templateUserData?.service2?.id}>
                  <div className="ser-link">
                    <div className="icon">
                      <SVG
                        src={`${ASSEST_URL}/${templateUserData?.service2?.svg?.filename_disk}`}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                        style={{ height: "23.434", width: "26.071" }}
                      />
                    </div>
                    <span className="service-name">
                      {templateUserData?.service2?.name}
                    </span>
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service3?.name ? (
                <li className="item" key={templateUserData?.service3?.id}>
                  <div className="ser-link">
                    <div className="icon">
                      <SVG
                        src={`${ASSEST_URL}/${templateUserData?.service3?.svg?.filename_disk}`}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                        style={{ height: "23.434", width: "26.071" }}
                      />
                    </div>
                    <span className="service-name">
                      {templateUserData?.service3?.name}
                    </span>
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service4?.name ? (
                <li className="item" key={templateUserData?.service4?.id}>
                  <div className="ser-link">
                    <div className="icon">
                      <SVG
                        src={`${ASSEST_URL}/${templateUserData?.service4?.svg?.filename_disk}`}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                        style={{ height: "23.434", width: "26.071" }}
                      />
                    </div>
                    <span className="service-name">
                      {templateUserData?.service4?.name}
                    </span>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>

            <p className="desc text-center mb-4">
              {templateUserData?.description ||
                `Award Winning web-developer with 7 years of experience in HTML
              ,CSS, LAMP, My main interests are object-oriented and
              user-centered design.`}
            </p>

            <div className="d-flex position-relative justify-content-between">
              <ul className="contact-detail mb-2">
                <li className="item">
                  <a
                    href={`tel:${
                      templateUserData?.mobileNumber
                        ? templateUserData?.mobileNumber
                        : "#"
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    <span className="icon">
                      <SVG
                        src={call}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                      />
                    </span>
                    <span className="link-text">
                      {templateUserData?.mobileNumber
                        ? templateUserData?.mobileNumber
                        : `+91 9999999999`}
                    </span>
                  </a>
                </li>
                {templateUserData?.isWebsite ? (
                  <li className="item">
                    <a
                      href={`${
                        templateUserData?.websiteLink
                          ? normalizeURL(templateUserData?.websiteLink)
                          : ""
                      }`}
                      target={"_blank"}
                      className="contact-link"
                      rel="noreferrer"
                    >
                      <span className="icon">
                        <SVG
                          src={map}
                          fill={templateUserData?.templateColor || "#c45f1c"}
                        />
                      </span>
                      <span className="link-text">
                        {templateUserData?.websiteLink
                          ? templateUserData?.websiteLink.replace(
                              /(^\w+:|^)\/\//,
                              ""
                            )
                          : `www.mobilevisit.com`}
                      </span>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="item">
                  <a
                    href={`mailto:${
                      templateUserData?.email ? templateUserData?.email : ""
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    <span className="icon">
                      <SVG
                        src={envelop}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                      />
                    </span>
                    <span className="link-text">
                      {templateUserData?.email
                        ? templateUserData?.email
                        : `youremailid@somedomain.com`}
                    </span>
                  </a>
                </li>
                <li className="item">
                  <a
                    href={`http://maps.google.com/?q=${
                      templateUserData?.address
                        ? templateUserData?.address
                        : `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India - 395009`
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    <span className="icon">
                      <SVG
                        src={location}
                        fill={templateUserData?.templateColor || "#c45f1c"}
                      />
                    </span>
                    <span className="link-text">
                      {templateUserData?.address ||
                        `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India -
                          395009`}
                    </span>
                  </a>
                </li>
              </ul>

              {auth ? (
                <></>
              ) : (
                <div className="d-flex flex-column text-end">
                  <span className="qr-icon bordered mt-1 mb-2">
                    <QRCodeSVG value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`} width="70" height="70"/>
                  </span>
                  <div className="d-flex justify-content-center text-center">
                    <button
                      type="button"
                      className="addcontact-btn"
                      aria-label="addcontact-btn"
                      onClick={() => downloadVCFFile(templateUserData)}
                    >
                      <SVG src={contactadd} width="38" height="20" />
                    </button>
                  </div>
                </div>
              )}
            </div>

            
            
            <ul className="social-media">
              {templateUserData?.isWhatsapp ? (
                <li className="item">
                  <a
                    href={`https://wa.me/${templateUserData?.whatsAppLink}`}
                    target={"_blank"}
                    className="social-link"
                    rel="noreferrer"
                  >
                    <SVG
                      src={whatsapp}
                      stroke={templateUserData?.templateColor || "#c45f1c"}
                      fill={templateUserData?.templateColor || "#c45f1c"}
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.isYouTube ? (
                <li className="item">
                  <a
                    href={
                      templateUserData?.youTubeLink
                        ? parseUrl(templateUserData?.youTubeLink)
                        : ""
                    }
                    target={"_blank"}
                    className="social-link"
                    rel="noreferrer"
                  >
                    <SVG
                      src={youtube}
                      stroke={templateUserData?.templateColor || "#c45f1c"}
                      fill={templateUserData?.templateColor || "#c45f1c"}
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.isInstagram ? (
                <li className="item">
                  <a
                    href={
                      templateUserData?.instagramLink
                        ? parseUrl(templateUserData?.instagramLink)
                        : ""
                    }
                    target={"_blank"}
                    className="social-link"
                    rel="noreferrer"
                  >
                    <SVG
                      src={instagram}
                      stroke={templateUserData?.templateColor || "#c45f1c"}
                      fill={templateUserData?.templateColor || "#c45f1c"}
                    />
                  </a>
                </li>
              ) : (
                " "
              )}
              {templateUserData?.isTwitter ? (
                <li className="item">
                  <a
                    href={
                      templateUserData?.twitterLink
                        ? parseUrl(templateUserData?.twitterLink)
                        : ""
                    }
                    target={"_blank"}
                    className="social-link"
                    rel="noreferrer"
                  >
                    <SVG
                      src={twitter}
                      stroke={templateUserData?.templateColor || "#c45f1c"}
                      fill={templateUserData?.templateColor || "#c45f1c"}
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.isFacebook ? (
                <li className="item">
                  <a
                    href={
                      templateUserData?.facebookLink
                        ? parseUrl(templateUserData?.facebookLink)
                        : ""
                    }
                    target={"_blank"}
                    className="social-link"
                    rel="noreferrer"
                  >
                    <SVG
                      src={facebook}
                      stroke={templateUserData?.templateColor || "#c45f1c"}
                      fill={templateUserData?.templateColor || "#c45f1c"}
                    />
                  </a>
                </li>
              ) : (
                ""
              )}

              {!auth ? (
                <li className="item">
                  <button
                    type="button"
                    aria-label="share button"
                    className="social-link share-button"
                    style={{ borderWidth: "0px", background: "transparent" }}
                    onClick={shareButton}
                  >
                    <SVG
                      src={shareNew}
                      style={{ width: "30px", height: "30px" }}
                      stroke={templateUserData?.templateColor || "#a29d06"}
                    />
                  </button>
                </li>
              ) : (
                ""
              )}
              {!auth ? (
                <li className="item">
                  <button
                    type="button"
                    aria-label="review button"
                    onClick={() => {
                      !auth && showReviewModal();
                    }}
                    style={{ borderWidth: "0px", background: "transparent" }}
                    className="social-link review-button"
                  >
                    <SVG
                      src={reviewNew}
                      style={{ width: "30px", height: "30px" }}
                      stroke={templateUserData?.templateColor || "#a29d06"}
                    />
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
            
            {auth ? (
              <></>
            ) : (
              <div className="d-flex align-items-center powered-block ps-2 justify-content-between">
                <div>
                  Powerd by :
                  <a
                    href="https://pocketsite.me"
                    target="_blank"
                    title="Pocketsite"
                    rel="noreferrer"
                  >
                    <span className="ps-2 pe-1">
                      <SVG
                        src={pocketlogo}
                        className="logosmall"
                        width={18}
                        height={18}
                      />
                    </span>
                    Pocketsite.me
                  </a>
                </div>
                <a
                  href="https://pocketsite.me"
                  target="_blank"
                  rel="noreferrer"
                  title="Create your card"
                  className="create-link"
                >
                  Create yours
                </a>
              </div>
            )}

          </div>
        </div>
      </div>
      {reviewModal && (
        <ReviewModal show={reviewModal} handleClose={showReviewModal} />
      )}
      {showFallback && (
        <SocialSharingModal
          show={showFallback}
          handleClose={handleClose}
        ></SocialSharingModal>
      )}
    </>
  );
};
export default Template08;
