import clsx from "clsx";
import React from "react";

type Props = {
  type: string;
  id?: string;
  value?: string;
  placeholder: string;
  className: string;
  name?: string;
  autoComplete?: string;
  pattern?: string;
  required?: boolean;
  maxLength?: number;
  isTouched?: boolean;
  isDisabled?: boolean;
  errorMsg?: string;
  autoFocus: boolean;
  inputMode?:
    | "search"
    | "text"
    | "tel"
    | "none"
    | "numeric"
    | "email"
    | "url"
    | "decimal"
    | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
};
const InputField: React.FC<Props> = ({
  type,
  id,
  value,
  className,
  placeholder,
  name,
  autoComplete,
  inputMode,
  pattern,
  required,
  maxLength,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  isDisabled,
  errorMsg,
  isTouched,
  autoFocus = false,
}) => {
  return (
    <>
      <input
        autoComplete={autoComplete || "off"}
        type={type}
        className={clsx(
          `form-control ${className}`,
          {
            "is-invalid": isTouched && errorMsg,
          },
          {
            "is-valid": isTouched && !errorMsg,
          }, 
        )}
        name={name}
        id={id}
        inputMode={inputMode}
        pattern={pattern}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        required={required}
        maxLength={maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={isDisabled}
        autoFocus={autoFocus}
      />
      {isTouched && errorMsg !== "" && (
        <div className="fv-plugins-message-container danger-validate">
          <div className="fv-help-block">{errorMsg}</div>
        </div>
      )}
    </>
  );
};

export default InputField;
