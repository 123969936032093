import { FeedbackFormModel, ReviewFormModel } from "./ReviewFormModel";

export const InitReviewForm: ReviewFormModel = {
    mobile_number: "",
    name: "",
    email: "",
    review_text: "",
    status: "",
    date_created: new Date(),
    user_template_id:"",
}

export const InitFeedbackForm: FeedbackFormModel = {
    mobile_number: "",
    name: "",
    email: "",
    feedback: "",
    status: "",
    date_created: new Date(),
    user_template_id:"",
}