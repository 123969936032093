import { PageLink } from "../../_start/layout/core";

export const editTemplateBreadCrumbs: Array<PageLink> = [
  {
    title: "Templates",
    path: "/edit-template/6",
    isActive: false,
  },
];

export const templatesBreadCrumbs: Array<PageLink> = [
  {
    title: "Tempaltes",
    path: "/edit-template/6",
    isActive: false,
  },
];

export const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/profile/preview",
    isActive: false,
  },
];
