import React from "react";
import Digitalcard from "../components/home/Digitalcard";
import Easystep from "../components/home/Easystep";

import BusinessCardGroup from "../components/home/BusinessCardGroup";
import ContentImage from "../components/home/ContentImage";
import SpecialDesign from "../components/home/SpecialDesign";
import MobiltyFinest from "../components/home/MobiltyFinest";
import Videosection from "../components/home/Videosection";
import StayConnected from "../components/home/StayConnected";

function HomeNew() {  
  return (
    <>
      <main className="main">
        <Digitalcard />
        <Easystep />
        <Videosection />
        <ContentImage />
        <BusinessCardGroup />
        <MobiltyFinest />
        <SpecialDesign />
        <StayConnected />
      </main>
    </>
  );
}

export default HomeNew;
