import React from "react";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import { PageDataContainer, PageTitle } from "../../../_start/layout/core";
import { profileBreadCrumbs } from "../../helper/BreadCrumbs"; 
import UserProfile from "../../../pages/UserProfile";
import NotFoundComponent from "../../pages/NotFound/NotFoundComponent";
import ProfilePreview from "../../../pages/ProfilePreview";

type Props = {};
const ProfilePage: React.FC<Props> = () => {
  return (
    <ProtectedRoute>
      <>
        <PageDataContainer breadcrumbs={profileBreadCrumbs} />
        <PageTitle>Profile Overview</PageTitle>
        <Routes> 
          <Route path="*" element={<NotFoundComponent />}></Route>
          <Route path="/user-profile" element={<UserProfile />} /> 
          <Route path="/preview" element={<ProfilePreview />} />
        </Routes>
      </>
    </ProtectedRoute>
  );
};

export default ProfilePage;
