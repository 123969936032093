import { persistReducer } from "redux-persist";
import { TemplateListModel } from "../models/TemplateListModel";
import storage from "redux-persist/lib/storage";
import { Action } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { getTemplateList } from "./TemplateListCRUD";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetTemplateList: "[Get Template List] Action",
  SetTemplateList: "[Set Template List] Action",
  UnSetTemplateList: "[UnSet Template List] Action",
  GetTemplateListCount: "[Get Template List Count] Action",
  SetTemplateListCount: "[Set Template List Count] Action",
  UnSetTemplateListCount: "[UnSet Template List Count] Action",
};

const initialTemplateState: InitTemplateState = {
  templateList: undefined,
  templateListCount: undefined,
};

export interface InitTemplateState {
  templateList?: TemplateListModel[];
  templateListCount?: number;
}

export const reducer = persistReducer(
  { storage, key: "templateList-01", whitelist: ["templateList"] },
  (
    state: InitTemplateState = initialTemplateState,
    action: ActionWithPayload<InitTemplateState>
  ) => {
    switch (action.type) {
      // case actionTypes.GetTemplateList: {
      //     const templateList = action.payload?.templateList;
      //     const templateListCount = action.payload?.templateListCount;
      //     return { ...state, templateList, templateListCount };
      // }
      case actionTypes.SetTemplateList: {
        const templateList = action.payload?.templateList;
        const templateListCount = action.payload?.templateListCount;
        return { ...state, templateList, templateListCount };
      }
      case actionTypes.UnSetTemplateList: {
        return initialTemplateState;
      }
      case actionTypes.UnSetTemplateListCount: {
        return initialTemplateState;
      }
      default:
        return state;
    }
  }
);

export const action = {
  getTemplateList: () => ({
    type: actionTypes.GetTemplateList,
  }),
  setTemplateList: (
    templateList: TemplateListModel,
    templateListCount: number
  ) => ({
    type: actionTypes.SetTemplateList,
    payload: { templateList, templateListCount },
  }),
  unSetTemplateList: () => ({
    type: actionTypes.UnSetTemplateList,
  }),
};

export function* TemplateListSaga() {
  yield takeLatest(
    actionTypes.GetTemplateList,
    function* getTemplateListSaga() {
      try {
        const {
          data: { data },
        } = yield getTemplateList();
         yield put(action.setTemplateList(data, data.length));
      } catch (e) {
        console.error("Unable to load list of templates, error trace is: ", e);
      }
    }
  );
}
