import * as Yup from "yup";
import { mobNum } from "../../../helper/helper";
export const ValidateContactUsForm = Yup.object().shape(
  {
    name: Yup.string()
      .min(5, "Minimum 5 Character")
      .max(80, "Maximum 80 Characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email number is required"),
    mobile_number: Yup.string()
      .min(10,"Please enter a valid 10 digit mobile number, without special characters and spaces")
      .required("Mobile number is required")
      .matches(mobNum,"Mobile number is not valid Cannot contain special characters or spaces"),
    subject: Yup.string()
      .min(5, "Minimum 5 Character")
      .max(30, "Maximum 30 Characters")
      .required("Subject is required"),
    message: Yup.string()
      .min(10, "Minimum 10 Character")
      .max(255, "Maximum 255 Character")
      .required("Message is Required"),
  },
  []
);
