import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as auth from "./redux/AuthRedux";
import * as templateUSerDataRedux from '../template/redux/TemplateUserDataRedux';
import * as templateListDataRedux from '../template/redux/TemplateListRedux';

export function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logout());
    dispatch(templateUSerDataRedux.actions.unsetTemplateUserData());
    dispatch(templateListDataRedux.action.unSetTemplateList());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
    </Routes>
  );
}
