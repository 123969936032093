
import fordribble from "../../../templates/images/misc/fordribble.gif";

const Loader = () => {
    return (
        <>
            <div className="loader-overlay">
                <div className="loader-body">
                    <img src={fordribble} alt="loading..." style={{ height:"100px" }}></img>
                </div>
            </div>
        </>
    );
}


export default Loader;