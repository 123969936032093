export const colors = [ 
  { color: "#66347F", lightColor: "#f6f1f9", isActive: false}, 
  { color: "#2E4F4F", lightColor: "#f0f6f6", isActive: false}, 
  { color: "#7D8F69", lightColor: "#f8f9f7", isActive: false}, 
  { color: "#443C68", lightColor: "#f5f4f9", isActive: false},  
  { color: "#3C2A21", lightColor: "#f8f4f2", isActive: false},  
  { color: "#144272", lightColor: "#f5f9fd", isActive: false},  
  { color: "#735F32", lightColor: "#f9f6f1", isActive: false},  
  { color: "#624F82", lightColor: "#f5f4f8", isActive: false},  
  { color: "#474E68", lightColor: "#f3f4f7", isActive: false},  
  { color: "#790252", lightColor: "#fff0fa", isActive: false},  
  { color: "#704F4F", lightColor: "#f8f6f6", isActive: false},  
  { color: "#395B64", lightColor: "#f4f8f9", isActive: false},  
  { color: "#774360", lightColor: "#f9f5f7", isActive: false},  
  { color: "#3F3351", lightColor: "#f5f3f8", isActive: false},  
  { color: "#607d8b", lightColor: "#f7f9f9", isActive: false},
  { color: "#1E5128", lightColor: "#f0faf2", isActive: false},
  { color: "#7D1935", lightColor: "#fcf0f3", isActive: false},
  { color: "#346751", lightColor: "#f3f9f7", isActive: false},
  { color: "#7E7474", lightColor: "#f9f8f8", isActive: false},
  { color: "#393E46", lightColor: "#f4f5f6", isActive: false},
  { color: "#595B83", lightColor: "#f6f6f9", isActive: false},
  { color: "#519872", lightColor: "#f6faf8", isActive: false},
  { color: "#87556F", lightColor: "#f9f6f8", isActive: false},
  { color: "#59405C", lightColor: "#f7f5f8", isActive: false},
  { color: "#525252", lightColor: "#f6f6f6", isActive: false},
  { color: "#00818A", lightColor: "#f8ffff", isActive: false},
  { color: "#557571", lightColor: "#f6f9f8", isActive: false},
  { color: "#476D7C", lightColor: "#f5f8f9", isActive: false},
  { color: "#5E3B4D", lightColor: "#f8f4f6", isActive: false},
  { color: "#435055", lightColor: "#f5f7f7", isActive: false},
  { color: "#556E53", lightColor: "#f6f8f6", isActive: false},
  { color: "#713045", lightColor: "#faf3f5", isActive: false},
  { color: "#A7727D", lightColor: "#fbf8f8", isActive: false},
];