import AOS from "aos";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../node_modules/aos/dist/aos.css";
AOS.init();
function SpecialDesign() {
  const { auth , templateUserData} = useSelector((state: any) => state);
 
  return (
    <>
      <div className="special-desing-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading-medium">Special designs for you</h2>
              <p className="mb-40">Business Cards of your choice</p>
            </div>

            <div className="col-md-4" data-aos="zoom-out-right">
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <img
                  src="assets/images/special01.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <img
                  src="assets/images/special02.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
            </div>
            <div className="col-md-4" data-aos="zoom-out-left">
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <img
                  src="assets/images/special03.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialDesign;
