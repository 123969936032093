import React, { useState, createContext } from "react";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import French from "../lang/fr.json";
import Spanish from "../lang/es.json";

export const Context = createContext({
  locale: "",
  selectLang: (e: any) => {},
});

const local: string = navigator.language;

let lang: {
  "app.header": string;
  "app.content": string;
  "app.channel.plug": string;
};
if (local === "fr") {
  lang = French;
} else if (local === "es") {
  lang = Spanish;
} else {
  lang = English;
}

const Wrapper = (props: any) => {
  const [locale, setLocale] = useState<string>(local);
  const [message, setMessage] = useState(lang);

  const selectLang = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "fr") {
      setMessage(French);
    } else if (newLocale === "es") {
      setMessage(Spanish);
    } else {
      setMessage(English);
    }
  };

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider locale={locale} messages={message}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
