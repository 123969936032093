import SVG from "react-inlinesvg";
import React from "react";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import InputField from "./edit-template/InputField";
import facebook from "../templates/images/facebook.svg";
import twitter from "../templates/images/twitter.svg";
import {
  WhatsappIcon, 
} from "react-share";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../setup";

import copyIcon from "../templates/images/icons/copy.svg";
import { PUBLIC_URL } from "../app/helper/EnvVar";
import CopyToClipboard from "react-copy-to-clipboard";

const ShareModal = (
  props: JSX.IntrinsicAttributes &
    Omit<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        "key" | keyof React.HTMLAttributes<HTMLDivElement>
      > & {
        ref?:
          | ((instance: HTMLDivElement | null) => void)
          | React.RefObject<HTMLDivElement>
          | null
          | undefined;
      },
      BsPrefixProps<"div"> & ModalProps
    > &
    BsPrefixProps<"div"> &
    ModalProps & { children?: React.ReactNode }
) => {
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="share-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-sharing">
            <ul className="social-media mb-4">
              <li className="item">
                <a href={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`} target={"_blank"} className="social-link whats-app" rel="noreferrer">
                  <WhatsappShareButton
                    url={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </a>
                <span>Whats App</span>
              </li>
             
              <li className="item">
                <a
                  href={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  target={"_blank"}
                  className="social-link twitter"
                  rel="noreferrer"
                >
                  <TwitterShareButton
                    url={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  >
                    <SVG src={twitter} />
                  </TwitterShareButton>
                </a>
                <span> Twitter</span>
              </li>
              <li className="item">
                <a
                  href={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  target={"_blank"}
                  className="social-link facebook"
                  rel="noreferrer"
                >
                  <FacebookShareButton
                    url={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  >
                    <SVG src={facebook} />
                  </FacebookShareButton>
                </a>
                <span>Facebook</span>
              </li>
            </ul>
          </div>
          <div className="sharelink-col">
            <b className="mb-3 d-flex">View Url</b>
              <div className="position-relative" style={{ marginBottom: "20px" }}>
                <span className="mb-3 pe-10"> 
                  <a href={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`} target="_blank" rel="noreferrer" >
                    {`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  </a>
                </span>
              </div>
          </div>
          <div className="sharelink-col">          
            <b className="mb-3 d-flex">Share Url</b>
            <CopyToClipboard
              onCopy={() => {
                toast.success("Copied to Clipboard");
              }}
              options={{ message: "Copied to Clipboard!" }}
              text={`${PUBLIC_URL}/${templateUserData?.mobileNumber!}`}
            >
              <div className="position-relative">
                <InputField
                  type="copy"
                  className="mb-3 pe-5"
                  placeholder=" "
                  value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                  autoFocus={false}
                  isDisabled={true}
                />
                <div className="copy-icon">
                  <button type="button" className="btn">
                    <SVG src={copyIcon} />
                  </button>
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
