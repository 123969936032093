/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HomeNew from "../../../pages/Home";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";

export const Home: React.FC = () => {
  return (
    <>
      <ProtectedRoute>
        <HomeNew />
      </ProtectedRoute>
    </>
  );
};
