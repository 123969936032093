 import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
 import Buttons from "../Buttons";
 function BusinessCardGroup() {

  const { auth , templateUserData} = useSelector((state: any) => state);
 
   return (
     <>
       <div className="content-image-section businesscard-section">
         <div className="container">
           <div className="row align-items-center">
             <div className="col-lg-6 ">
               <div
                 className="img-col mb-sm-0 mb-4"
                 data-aos="fade-up"
                 data-aos-anchor-placement="top-center"
               >
                 <img
                   src="assets/images/card-group.webp"
                   alt="card-view"
                   width="499"
                   height="515"
                 />
               </div>
             </div>
             <div className="col-lg-6">
               <h2 className="heading-medium fs-24">
                 One business card, <br /> endless possibilities
               </h2>
               <p className="mb-40">
                  Unleash the potential of our platform as you create a professional profile that transforms into a dynamic digital 
                  business card. Personalize your card by adding your business logo and profile image, 
                  instantly capturing attention and reinforcing your brand identity. Highlight your expertise by selecting and 
                  prominently displaying your key services, making it simple for others to understand your strengths. 
                  Boost your online presence by including your social media details, seamlessly integrating your digital footprint. 
                  Craft an engaging "About" section that showcases your unique value proposition. 
                  Share your versatile profile effortlessly through various channels, from social media platforms 
                  to saving it as a convenient vCard (.vcf) for easy contact integration. 
                  Experience the perfect blend of a convenient digital business card and a comprehensive 
                  professional profile, expanding your network and unlocking exciting opportunities.
               </p>
               <Link to={auth.isAuthorized ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}` : "/auth/login"}>
                <Buttons
                   btnText={"Start Now"}
                   buttonName={"button"}
                   className="px-4"
                 ></Buttons>
               </Link>
             </div>
           </div>
         </div>
       </div>
     </>
   );
 }

 export default BusinessCardGroup;
