/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, lazy } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as templateUserDataRedux from "../../modules/template/redux/TemplateUserDataRedux";
import { nanoid } from "nanoid";
import _toNumber from "lodash/toNumber";
import { RootState } from "../../../setup";
import EditTemplateNew from "../../../pages/EditTemplate";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";
import { AuthDataRedux } from "../../modules/auth/redux/ReduxDispatchExport";

type TemplateID = {
  id: string;
};

type Props = {};

const updateUrl = (url: string) => {
  window.history.pushState({}, "", url);
  window.history.replaceState({}, "", url);
};

const importView = (tempId: number) =>
  lazy(() =>
    import(`./components/Templates/Template${tempId}`).catch(
      () => import(`../NotFound/NotFoundComponent`)
    )
  );

export const EditTemplate: React.FC<Props> = () => {
  const { id } = useParams<TemplateID>();
  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [templateId, setTemplateId] = useState<number>(_toNumber(id));
  const [templateIds, setTemplateIds] = useState<number[]>();
  const [templateCurrentId, setTemplateCurrentId] = useState<number>(1);
  const { templateList } = useSelector(
    (state: RootState) => state.template
  );
  let tempArrayIdList: number[] = [];
  const createArrayListOfID = () => {
    templateList!?.forEach((element : any) => {
      tempArrayIdList!.push(parseInt(element.id))
    });
    setTemplateIds(tempArrayIdList)
  }

  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData
  );
  const [, setViews] = useState<any>([]);

  useEffect(()=>{
    if (!auth.isAuthorized) {
      dispatch(AuthDataRedux.actions.logout());
      navigate("/login")
    }
  }, [auth.isAuthorized]);

  useEffect(() => {
    createArrayListOfID()
    var idx = templateIds!?.indexOf(templateId);
    if(idx === -1){
      setTemplateCurrentId(idx + 2);
    }else{
      setTemplateCurrentId(idx + 1);
    }
  
    const tempId = templateId;
    async function loadViews(tempId: number) {
      const componentPromises = async (tempId: number) => {
        const View = await importView(tempId);
        const naid: string = nanoid(10);
        return <View key={naid} editMode={true} />;
      };
      componentPromises(tempId).then(setViews);
    }

    if(templateId !== 6 && templateId !== 7 && templateId !== 8 && templateId !== 9 && templateId !== 10){
      navigate("/edit-template/6") 
      setTemplateId(6)   
    } else{      
    loadViews(tempId);
    dispatch(
      templateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData!,
        selectedTemplateID: templateId,
      })
    );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, templateId, templateCurrentId, templateList]);

  const handleNextClick = () => {
    var idx = templateIds!.indexOf(templateId);
    if (idx === templateIds!.length! - 1) {
      setTemplateId(templateIds![0]);      
      updateUrl(templateIds![0].toString())
      setTemplateCurrentId(idx)
    }else{
      setTemplateId(templateIds![idx + 1])
      setTemplateCurrentId((idx + 1) + 1)
      updateUrl(templateIds![idx + 1].toString())
    }
  }
  
  const handlePreviousClick = () => {
    var idx = templateIds!.indexOf(templateId);
    if (idx === 0) {
      setTemplateId(templateIds![templateList!.length! - 1]);
      setTemplateCurrentId((templateList!.length! - 1) + 1)
      updateUrl(templateIds![templateList!.length! - 1].toString())
    }else{
      setTemplateId(templateIds![idx - 1])
      setTemplateCurrentId((idx - 1) + 1)
      updateUrl(templateIds![idx - 1].toString())
    }
  }

  return (
    <>
      <ProtectedRoute>
        <EditTemplateNew
          templateId={templateId}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          templateList={templateIds?.length!}
          currentTemplateNumber={templateCurrentId}
        />
      </ProtectedRoute>
    </>
  );
};
