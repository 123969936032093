import AOS from "aos";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../node_modules/aos/dist/aos.css";

import Buttons from "../Buttons";
AOS.init();

function Digitalcard() {
  
  const { auth , templateUserData} = useSelector((state: any) => state);
  return (
    <>
      <div
        id="home"
        className="business-card-section d-flex align-items-center ornament-bg gradient-background"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-up">
              <div className="content-cols">
                <h1 className="heading-main mb-40 font-600">
                  Make Your <br /> Digital Business <br /> Cards
                </h1>
                <p>
                  Select templates and customize them using our helpful design
                  themes. Send your professional card via email, share on social
                  media etc., and make a solid professional network.
                </p>
                <Link
                  to={
                    auth.isAuthorized
                      ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                      : "/auth/login"
                  }
                >
                  <Buttons
                    btnText={"Create Now"}
                    buttonName={"button"}
                    className=""
                  ></Buttons>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6 text-center text-lg-end order-1 order-lg-2"
              data-aos="fade-left"
            >
              <div className="img">
                <img   
                  src="assets/images/card-img.png"
                  alt="battery100"
                  width="514"
                  height="817"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Digitalcard;
