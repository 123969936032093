import { Action } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions as actionsTemplateData } from "../../template/redux/TemplateUserDataRedux";
import { createTemplateUserDataByUserID } from "../../template/redux/TemplateUserDataCRUD";
import { UserModelPocket } from "../models/UserModel";
import {
  getUserByToken2,
  logoutUser,
  refreshAccessToken,
  verifyMobileNumber,
  verifyOtp,
} from "./AuthCRUD";
import { OTPResponseDataModel } from "../models/AuthModel";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: "[Login] Auth Action",
  Logout: "[Logout] Auth Redux Action",
  UserLoaded: "[Load User] Auth Action",
  UserLoadedRegistered: "[Load Registered User] Register Action",
  SetUserAuthorized: "[Set User Authorized] Auth Action",
  SetOTP: "[Set OTP] Auth Action",
  UnsetOTP: "[Unset OTP] Auth Action",
  SetMobileNumber: "[Set MobileNumber] Action",
  UnsetMobileNumber: "[Unset MobileNumber] Action",
  VerifyOTP: "[Verify OTP] Auth Action",
  messageOTP: "[Message OTP] Action",
  RefreshToken: "[Refresh Token] Auth Action",
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  expires: undefined,
  userLoginTime: undefined,
  isAuthorized: false,
  OTP: undefined,
  message: undefined,
  mobileNumber: undefined,
};

export interface IAuthState {
  user?: UserModelPocket;
  accessToken?: string;
  refreshToken?: string;
  expires: number | undefined;
  userLoginTime?: number;
  isAuthorized?: boolean;
  OTP?: undefined | string;
  message?: undefined | string;
  mobileNumber?: undefined | string;
}

export const reducer = persistReducer(
  {
    storage,
    key: "auth-01",
    whitelist: ["user", "accessToken", "isAuthorized", "refreshToken"],
  },
  (
    state: IAuthState = initialAuthState,
    action: ActionWithPayload<IAuthState>
  ) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        const refreshToken = action.payload?.refreshToken;
        const expires = action.payload?.expires;
        const userLoginTime = action.payload?.userLoginTime;
        return { ...state, accessToken, refreshToken, expires, userLoginTime};
      }

      case actionTypes.Logout: {
        return {...initialAuthState, isAuthorized: false, accessToken: undefined, refreshToken: undefined};
      }
      
      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user, isAuthorized: true };
      }

      case actionTypes.UserLoadedRegistered: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUserAuthorized: {
        const isAuthorized = action.payload?.isAuthorized;
        return { ...state, isAuthorized: isAuthorized };
      }

      case actionTypes.SetOTP: {
        const OTP = action.payload?.OTP;
        return { ...state, OTP };
      }

      case actionTypes.UnsetOTP: {
        return { ...state, OTP: undefined };
      }

      case actionTypes.messageOTP : {
        const message = action?.payload?.message
        return {...state, message }
      }

      case actionTypes.SetMobileNumber: {
        const mobileNumber = action.payload?.mobileNumber;
        return { ...state, mobileNumber };
      }

      case actionTypes.UnsetMobileNumber: {
        return { ...state, mobileNumber: undefined };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string, refreshToken: string, expires: number, userLoginTime: number) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, expires, userLoginTime},
  }),
  setMobileNumber: (mobileNumber: string) => ({
    type: actionTypes.SetMobileNumber,
    payload: { mobileNumber },
  }),
  // unsetMobileNumber: () => ({ type: actionTypes.UnsetMobileNumber }),
  verifyotp: (otp: string, mobileNumber: string) => ({
    type: actionTypes.VerifyOTP,
    payload: { otp, mobileNumber },
  }),
  messageOTP: (message : string) => ({
    type: actionTypes.messageOTP,
    payload : { message }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  fulfillUser: (user: UserModelPocket) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  fulfillUserOnRegister: (user: UserModelPocket) => ({
    type: actionTypes.UserLoadedRegistered,
    payload: { user },
  }),
  setUserAuthorized: (isAuthorized: boolean) => ({
    type: actionTypes.SetUserAuthorized,
    payload: { isAuthorized },
  }),
  refreshToken: () => ({ type: actionTypes.RefreshToken }),
};

export function* saga() {  
  yield takeLatest(actionTypes.SetMobileNumber, function* saga ({payload} : any) {
    const { mobileNumber } = payload;
    const { data } = yield verifyMobileNumber(mobileNumber)
    if(data?.response?.status === 500 || data?.response?.data?.errors[0]?.code === 500){      
      toast.error("OTP initiate failed")
    }else if(data?.status === 401){
      yield put(actions.messageOTP("OTP initiate failed"));
      toast.error(data?.data?.message)
    }else if(data?.data){
      toast.success(`OTP sent to mobile number`,{
        autoClose: 2000
      })
    }    
}); 

yield takeLatest(actionTypes.VerifyOTP, function* saga ({payload}: any) {
    const { otp, mobileNumber } = payload
    const { data }: OTPResponseDataModel = yield verifyOtp(otp, mobileNumber);
    const { access_token, refresh_token, expires } : any = data?.data;
    let now = new Date().getTime();
    if(data?.response?.status === 500 || data?.response?.data?.errors[0]?.code === 500){
      yield put(actions.messageOTP("OTP verification failed"));
      toast.error("OTP verification failed")
    }else if(data?.status === 401){
      yield put(actions.messageOTP("OTP verification failed"));
      toast.error(data?.data?.message)
    }else if(!data?.data?.templateData) {
      yield put(actions.login(access_token, refresh_token, expires, now));
      const { userByToken } =  yield getUserByToken2(data?.data?.access_token);
      yield put(actions.fulfillUser(userByToken?.data?.data));
      yield call(createTemplateUserDataByUserID);
    }else{
      yield put(actions.login(access_token, refresh_token, expires, now));
      yield put(actions.fulfillUser(data?.data?.templateData));
      yield put(actionsTemplateData.setTemplateUserData(data?.data?.templateData));
      yield put(actions.messageOTP("OTP verified"));
      yield put(actions.setUserAuthorized(true));
    }
});

  yield takeLatest(actionTypes.RefreshToken, function* refreshTokenSaga() {
    try {
      const {
        data: {
          data: { access_token, refresh_token, expires },
        },
      } = yield refreshAccessToken();
      let now = new Date().getTime();
      yield put(actions.login(access_token, refresh_token, expires, now));
    } catch (error) {
      yield logoutUser();
      yield put(actions.logout());
    }
  });

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    try {
      yield logoutUser();
      yield put(actions.logout());
    } catch (error) {
      console.log(error);
    }
  });
}
