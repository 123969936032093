import React from "react";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
//  import "../../../../aos/dist/aos.css";

import SVG from "react-inlinesvg";
AOS.init({
  once: true,
});

function Easystep() {
  return (
    <>
      <div id="steps" className="easy-step-section" data-aos={"fade-up"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading-medium">
                How to make your Pocketsite card in <br /> easy steps
              </h2>
            </div>
            <div className="col-md-4">
              <div className="step-block">
                <div className="step-icon">
                  <SVG src="./assets/images/select-design.svg" />
                </div>
                <div className="title">
                  Select design according to your taste
                </div>
                <p>
                  Choose a design that you love to create a profile that reflects your personality. 
                  This profile will not only highlight your skills and expertise but also represent your personal taste. 
                  Select a template that resonates with you and use it as the basis for an attractive and visually appealing 
                  online presence.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="step-block">
                <div className="step-icon">
                  <SVG src="./assets/images/detail-pen.svg" />
                </div>
                <div className="title">
                  Fill in the details, and personalize it
                </div>
                <p>
                  Customize your design with your personal touch. 
                  Highlight your skills, expertise, and don't forget to include relevant contact information. 
                  Make your profile truly yours by adding any additional sections that showcase you.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="step-block">
                <div className="step-icon">
                  <SVG src="./assets/images/share.svg" />
                </div>
                <div className="title">
                  Preview & share to gain impressive outcomes
                </div>
                <p>
                  Take a moment to preview your personalized profile and ensure it perfectly captures your desired image. 
                  With confidence, share it with potential clients, employers, or colleagues, and leave a lasting impression. 
                  Stand out from the crowd and unlock exciting new opportunities with your one-of-a-kind and captivating profile. 
                  Your future awaits!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Easystep;
