// import { toAbsoluteUrl } from "../../../helpers";
import logo from "../../../../templates/images/logo.svg";

export function FallbackView() {
  return (
    <div className="splash-screen">
      <img
        // src={toAbsoluteUrl("/media/logos/main-logo.png")}
        // src={toAbsoluteUrl("/assets/images/logo.svg")}
        // src={toAbsoluteUrl(logo)}
        src={logo}
        alt="pocketsite.me"
        width={72}
        height={72}
      />
      <span>Loading ...</span>
    </div>
  );
}
