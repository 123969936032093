import FileSaver from "file-saver";
import { getVCFFile } from "./app/modules/template/redux/TemplateUserDataCRUD";
 
export const downloadVCFFile = async (data: any) => {
    const vcfFile: any = await getVCFFile(data).then(res => res).catch(err => err);
    localStorage.setItem('vcf-download', 'true')
    const element = document.createElement("a");
    element.download = `${data?.firstName}${data?.lastName}.vcf`;
    var blob = new Blob([vcfFile?.data?.vcf], { type: "text/vcard;charset=utf-8" })
    FileSaver.saveAs(
        blob,
        `${data?.firstName}${data?.lastName}.vcf`,
        true
      );
    document.body.appendChild(element);
    element.click();
}