import { useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect, {
  ActionMeta,
  components,
  MultiValue,
  SingleValue,
} from "react-select";
import { ASSEST_URL } from "../app/helper/EnvVar";
import { iDToValueResolveSelect } from "../app/helper/helper";
import { ServicesListModel } from "../app/modules/template/models/ServicesModel";
import { RootState } from "../setup";
import { KTSVG } from "../_start/helpers";
import SVG from "react-inlinesvg";
import close from "../../src/templates/images/icons/close.svg";

type Props = {
  placeholder?: string;
  onChange?:
    | ((
        newValue:
          | SingleValue<{ options: any[] }>
          | MultiValue<{ options: any[] }>,
        actionMeta: ActionMeta<{ options: any[] }>
      ) => void)
    | undefined;
  isTouched?: boolean;
  isMulti?: boolean;
  errorMsg?: any;
  name?: string;
  value?: any;
  preValue?: any;
  id?: string;
  data?: ServicesListModel[];
  selectedServiceList?: any[];
  tabIndex?: number;
  autoFocus?: boolean;
  reference?: any;
  handleRemove?: (e: React.MouseEvent<any>) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
};

const { Option } = components;

const IconOption = (props: any) => (
  <Option {...props}>
    <div className="row align-items-center" style={{ fontSize: "15px" }}>
      <div className="col-2">
        <KTSVG
          className="svg-icon-1 svg-icon-lg-1"
          path={`${ASSEST_URL}/${props?.data?.svg?.filename_disk}`}
          svgClassName="fillSvgColor"
          style={{
            color: "#20d489",
            fontSize: "20px",
            width: "20px",
            height: "20px",
          }}
        />
      </div>
      <div className="col-10 ps-0 service-label">{props.data.label}</div>
    </div>
  </Option>
);

const SelectDropdown: React.FC<Props> = ({
  placeholder,
  onChange,
  isTouched,
  errorMsg,
  name,
  id,
  data,
  onBlur,
  isMulti,
  value,
  preValue,
  onFocus,
  handleRemove,
  selectedServiceList,
  tabIndex,
  autoFocus,
  reference,
}) => {
  const [servicesModal, setServicesModal] = useState<boolean>(false);
  const userTemplateData = useSelector(
    (state: RootState) => state.templateUserData?.templateUserData!
    );
    
    const newOption = iDToValueResolveSelect(data);
    
    var props = ['value', 'label', "svg"];
    var result = newOption.filter((o1: any) => {
    return !selectedServiceList!.some((o2 : any) => {
        return o1.value === o2;      
    });
  }).map((o : any) => {
    // use reduce to make objects with only the required properties
    // and map to apply this to the filtered array as a whole
    return props.reduce((newo: any, name: any) => {
        newo[name] = o[name];
        return newo;
    }, {});
  });

  const options = [
    {
      options: [...result],
    },
  ];

  const handleClose = () => {
    if (userTemplateData.industry) {
      setServicesModal(!servicesModal);
    } else {
    }
  };

  const CustomNoOption = () => {
    return (
      <>
        {userTemplateData?.industry?.id ? (
          <>
            <p>
              Didn't find the service you are looking for?, let us know what
              services we need to add.
            </p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClose}
            >
              Request New Service(s)
            </button>
          </>
        ) : (
          <>
            <p>
              There is no match for service
            </p>
          </>
        )}
      </>
    );
  };

  const colorStyles = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#333333",
      };
    },
    control:(defaultStyles: any) => {
      return {
        ...defaultStyles,
        paddingLeft: '40px',
        height: '46px'
      };
    }
  };
  
  const defaultcolorStyles = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
      };
    },
    control:(defaultStyles: any) => {
      return {
        ...defaultStyles,
        paddingLeft: '40px',
        height: '46px'
      };
    }
  };

  return (
    <>   
      {value ? (
        <ReactSelect
          placeholder={value?.name}
          className="mb-3"
          styles={value?.name ? colorStyles : defaultcolorStyles}
          options={options}
          onBlur={onBlur}
          onFocus={onFocus}
          components={{ Option: IconOption }}
          onChange={onChange}
          name={name}
          id={id}
          noOptionsMessage={CustomNoOption}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          ref={reference}
          tabSelectsValue={false}
          openMenuOnFocus={reference ? true : false}
        ></ReactSelect>
      ) : (
        <>
          <ReactSelect
            placeholder={placeholder}
            className="mb-3"
            styles={value?.name ? colorStyles : defaultcolorStyles}
            options={options}
            onBlur={onBlur}
            onFocus={onFocus}
            components={{ Option: IconOption }}
            onChange={onChange}
            name={name}
            id={id}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            ref={reference}
            tabSelectsValue={false}
            openMenuOnFocus={reference ? true : false}
          ></ReactSelect>
        </>
      )}
      {value && (
        <div
          className="remove-service-icon ps-4"
          onClick={handleRemove}
          style={{ cursor: "pointer" }}
        >
          <SVG src={close} className="logo-main" />
        </div>
      )}
    </>
  );
};
export default SelectDropdown;
