 import Buttons from "../Buttons";

 import AOS from "aos";
 import "../../../node_modules/aos/dist/aos.css";
 import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
 AOS.init();

 function ContentImage() {

  const { auth , templateUserData} = useSelector((state: any) => state);
 
   return (
     <>
       <div className="content-image-section bg-sky-blue" id="business-cards">
         <div className="container">
           <div className="row align-items-center">
             <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-right">
               <h2 className="heading-medium fs-24">
                 Business cards meet the digital age
               </h2>
               <p className="mb-40"> 
                <ul className="ps-3">
                 <li>
                 Business Cards Go Digital with PocketSite. 
                 </li>
                 <li>
                 Business Cards Meet the Digital Age with PocketSite.
                 </li> 
                 <li>
                 Revolutionize Business Cards with PocketSite for Digital Networking.
                 </li>   
                </ul>
                Embrace the future of networking as business cards meet the digital age through PocketSite. 
                Effortlessly share contact details and expertise with a click. Connect in the digital realm, 
                leaving lasting impressions beyond paper. 
                Elevate your professional presence with PocketSite."
               </p>
               <Link to={auth.isAuthorized ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}` : "/auth/login"}>
                <Buttons
                   btnText={"Start Now"}
                   buttonName={"button"}
                   className="px-4"
                 ></Buttons>
               </Link>
             </div>
             <div
               className="col-lg-6 text-center mt-4 mt-lg-0 order-1 order-lg-2"
               data-aos="fade-left"
             >
               <div className="img-col -mt-44 mb-5 mb-lg-0">
                 <img
                   src="assets/images/card-view.png"
                   alt="card-view"
                   width="420"
                   height="602"
                 />
               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   );
 }

 export default ContentImage;
