import { axiosClient } from "../../../../setup/axios/SetupAxios";
import { TEMPLATES } from "../../../helper/APIURL";
// import { get } from "../../../helper/api_helper";
import { TemplateListModel } from "../models/TemplateListModel";

export const getTemplateList = () => {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}?fields=*,image.*`);
};

export function getTemplateByID(id: string) {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}/${id}?fields=*,image.*`);
}
