import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { RootState } from "../../../../setup";
import { TemplateUserDataModel } from "../../template/models/TemplateUserDataModel";
import { AuthDataRedux } from "../redux/ReduxDispatchExport";
import { mobNum } from "../../../helper/helper";

import "../../../../scss/login/authentication.scss";
import SVG from "react-inlinesvg";
import InputField from "../../../../components/edit-template/InputField";
import Buttons from "../../../../components/Buttons";
import cardAuth from "../../../../templates/images/card-auth.jpeg";
import logoCenter from "../../../../templates/images/logo-center.svg";

const loginSchema = Yup.object().shape({
  mobileNumber: Yup.string().max(10,
    "Please enter a valid 10 digit mobile number, without special characters and spaces"
    ).matches(mobNum, "Mobile number is not valid")
    .required("Mobile number is Required"),
});

const initialValues = {
  mobileNumber: "",
  // password: "demo",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
type Props = {};

export const Login: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthorized = useSelector(
    (state: RootState) => state.auth.isAuthorized
  );
  const templateUserData: TemplateUserDataModel = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  useEffect(() => {
    if (isAuthorized) {
      navigate(`/edit-template/${templateUserData?.selectedTemplateID}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);
  
  const formik = useFormik({
    initialValues: templateUserData!?.mobileNumber
      ? { mobileNumber: templateUserData!?.mobileNumber }
      : initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(AuthDataRedux.actions.setMobileNumber(values.mobileNumber));
      const tst = document.getElementById('toast-id')
      if(tst){
        setTimeout(() => {
          navigate(`/auth/otpverify`);
        }, 1000);
      }
    },
  });

  return (
    <>
      <main className="main">
        <div className="authentication-page">
          <div className="container">
            <div className="auth-inner">
              <div className="row">
                <div className="col-lg-5 order-2 order-lg-1">
                  <div className="side-left  text-center">
                    <div className="top-col  ">
                      <h2 className="title">
                        A few click away <br />
                        from creating your <br />
                        stunning digital card.
                      </h2>
                    </div>
                    <img
                      src={cardAuth}
                      alt="card-auth"
                      width={321}
                      height={321}
                    />
                  </div>
                </div>
                <div className="col order-1 order-lg-2  d-flex flex-column justify-content-center align-items-center">
                  <form
                    className="auth-form w-100"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="kt_login_signin_form"
                  >
                    <a
                      href="/"
                      className="logo-center mb-5 d-flex justify-content-center"
                    >
                      <SVG src={logoCenter} className="logo-main" />
                    </a>
                    <div className="field-wrap">
                      <div className="mt-4 text-start d-flex flex-column">
                        <div className="floating-group">
                          <InputField
                            type="tel"
                            placeholder=" "
                            {...formik.getFieldProps("mobileNumber")}
                            className={clsx(
                              "form-control form-control-lg",
                              {
                                "is-invalid":
                                  formik.touched.mobileNumber &&
                                  formik.errors.mobileNumber,
                              },
                              {
                                "is-valid":
                                  formik.touched.mobileNumber &&
                                  !formik.errors.mobileNumber,
                              }
                            )}
                            name="mobileNumber"
                            autoComplete="off"
                            autoFocus={true}
                          />
                          <label className="float-label">Enter Mobile No</label>
                        </div>

                        {formik.touched.mobileNumber &&
                          formik.errors.mobileNumber && (
                            <div className="fv-plugins-message-container danger-validate">
                              <div className="fv-help-block">
                                {formik.errors.mobileNumber}
                              </div>
                            </div>
                          )}
                      </div>
                      <Buttons
                        btnText={!loading ? "Continue" : "Send OTP..."}
                        buttonName={"button"}
                        type="submit"
                        className="w-100 min-w-160"
                        id="kt_login_signin_form_submit_button"
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.values.mobileNumber
                        }
                      ></Buttons>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
