import { IThemeConfig } from "./ThemeModels";
import {
  REACT_APP_THEME_ASIDE_CONTENT,
  REACT_APP_THEME_ASIDE_DISPLAY,
  REACT_APP_THEME_ASIDE_FIXED,
  REACT_APP_THEME_ASIDE_MENU,
  REACT_APP_THEME_ASIDE_MINIMIZED,
  REACT_APP_THEME_ASIDE_PRIMARYDISPLAY,
  REACT_APP_THEME_ASIDE_SECONDARYDISPLAY,
  REACT_APP_THEME_ASIDE_TOGGLE,
  REACT_APP_THEME_CONTENT_LAYOUT,
  REACT_APP_THEME_CONTENT_WIDTH,
  REACT_APP_THEME_FOOTER_WIDTH,
  REACT_APP_THEME_HEADER_DISPLAY,
  REACT_APP_THEME_HEADER_FIXED_DESKTOP,
  REACT_APP_THEME_HEADER_FIXED_TABLETANDMOBILE,
  REACT_APP_THEME_HEADER_WIDTH,
  REACT_APP_THEME_LOADER_DISPLAY,
  REACT_APP_THEME_LOADER_TYPE,
  REACT_APP_THEME_MEGAMENU_DISPLAY,
  REACT_APP_THEME_SCROLLTOP_DISPLAY,
  REACT_APP_THEME_SIDEBAR_BGCOLOR,
  REACT_APP_THEME_SIDEBAR_CONTENT,
  REACT_APP_THEME_SIDEBAR_DISPLAY,
  REACT_APP_THEME_SIDEBAR_DISPLAY_FOOTER,
  REACT_APP_THEME_SIDEBAR_DISPLAY_FOOTER_BUTTON,
  REACT_APP_THEME_SIDEBAR_SHOWN,
  REACT_APP_THEME_SIDEBAR_TOGGLE,
  REACT_APP_THEME_TOOLBAR_BREADCRUMB,
  REACT_APP_THEME_TOOLBAR_DISPLAY,
  REACT_APP_THEME_TOOLBAR_WIDTH,
} from "../../../app/helper/EnvVar";

export const DefaultThemeConfig: IThemeConfig = {
  loader: {
    display: REACT_APP_THEME_LOADER_DISPLAY,
    type: REACT_APP_THEME_LOADER_TYPE, // Set default|spinner-message|spinner-logo to hide or show page loader
  },
  scrolltop: {
    display: REACT_APP_THEME_SCROLLTOP_DISPLAY,
  },
  header: {
    display: REACT_APP_THEME_HEADER_DISPLAY, // Set true|false to show or hide Header
    width: REACT_APP_THEME_HEADER_WIDTH, // Set fixed|fluid to change width type
    fixed: {
      desktop: REACT_APP_THEME_HEADER_FIXED_DESKTOP, // Set true|false to set fixed Header for desktop mode
      tabletAndMobile: REACT_APP_THEME_HEADER_FIXED_TABLETANDMOBILE, // Set true|false to set fixed Header for tablet and mobile modes
    },
  },
  megaMenu: {
    display: REACT_APP_THEME_MEGAMENU_DISPLAY, // Set true|false to show or hide Mega Menu
  },
  aside: {
    display: REACT_APP_THEME_ASIDE_DISPLAY, // Set true|false to show or hide Aside
    fixed: REACT_APP_THEME_ASIDE_FIXED, // Set true|false to enabled or disable Fixed Aside mode
    menu: REACT_APP_THEME_ASIDE_MENU, // Set main|documentation menu for Aside
    primaryDisplay: REACT_APP_THEME_ASIDE_PRIMARYDISPLAY, // Set true|false to show or hide aside primary panel
    secondaryDisplay: REACT_APP_THEME_ASIDE_SECONDARYDISPLAY, // Set true|false to show or hide Aside Secondary
    toggle: REACT_APP_THEME_ASIDE_TOGGLE, // Set true|false to enabl Aside toggle
    minimized: REACT_APP_THEME_ASIDE_MINIMIZED, // Minimize aside by default
    content: REACT_APP_THEME_ASIDE_CONTENT, // Set "menu" | "docs"
  },
  content: {
    width: REACT_APP_THEME_CONTENT_WIDTH, // Set fixed|fluid to change width
    layout: REACT_APP_THEME_CONTENT_LAYOUT, // Set default|docs
  },
  toolbar: {
    display: REACT_APP_THEME_TOOLBAR_DISPLAY, // Set true|false to show or hide Subheader
    width: REACT_APP_THEME_TOOLBAR_WIDTH, // Set fixed|fluid to change width type,
    breadcrumb: REACT_APP_THEME_TOOLBAR_BREADCRUMB, // Set true|false to show or hide Breadcrumb
  },
  footer: {
    width: REACT_APP_THEME_FOOTER_WIDTH, // Set fixed|fluid to change width type
  },
  sidebar: {
    display: REACT_APP_THEME_SIDEBAR_DISPLAY, // Set true|false to add or remove  Sidebar
    toggle: REACT_APP_THEME_SIDEBAR_TOGGLE, // Set true|false to enable Sidebar show and hide toggle
    shown: REACT_APP_THEME_SIDEBAR_SHOWN, // Set true|false to by default show or hide Sidebar
    content: REACT_APP_THEME_SIDEBAR_CONTENT, // Set general|user|shop content for Sidebar
    bgColor: REACT_APP_THEME_SIDEBAR_BGCOLOR, // Set background class as bg-white|bg-info for Sidebar,
    displayFooter: REACT_APP_THEME_SIDEBAR_DISPLAY_FOOTER, // Set true|false to display Sidebar Footer
    displayFooterButton: REACT_APP_THEME_SIDEBAR_DISPLAY_FOOTER_BUTTON, // Set true|false to display Sidebar Footer Button
  },
};
