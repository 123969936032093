import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Buttons from "../Buttons";
function MobilityFinest() {
  const { auth , templateUserData} = useSelector((state: any) => state);
 
  return (
    <>
      <div className="content-image-section bg-light-blue mobile-finet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <h2 className="heading-medium fs-24">Mobility at its finest</h2>
              <p className="mb-40">
                Experience the ultimate mobility with our platform's seamless profile sharing system. 
                Effortlessly share your professional profile with just a few taps, whether it's through social media, email, 
                or saving it as a convenient vCard. Connect with potential clients or employers on the go, 
                ensuring your profile is always within reach. Embrace the freedom of mobility and expand your 
                network with ease, unlocking new opportunities wherever you are. Elevate your professional presence 
                with our hassle-free sharing features and stay connected in today's fast-paced world.
              </p>
              <Link to={auth.isAuthorized ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}` : "/auth/login"}>
                <Buttons
                  btnText={"Start Now"}
                  buttonName={"button"}
                  className="btn btn-primary px-4"
                ></Buttons>
              </Link>
            </div>
            <div className="col-lg-6 text-center order-1 order-lg-2">
              <div className="img-col -mt-44">
                <img
                  src="assets/images/finest-img.webp"
                  alt="finest-img"
                  width="462"
                  height="536"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobilityFinest;
