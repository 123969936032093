import {useMemo} from "react";
import { VALIDATE_OTP_IS_DIGIT } from "./constants";
import { OTPInputType } from "./OTPInputTypeDef";

const OTPInput: React.FC<OTPInputType> = (props) => {
    const {otpvalue: otpValue, otplength: otpLength = 6, styleclasses, onChange} = props;
    const valueItems = useMemo(() => {
        const valueArray = otpValue.split('');
        const items: Array<string> = [];
    
        for (let i = 0; i < otpLength; i++) {
          const char = valueArray[i];
    
          if (VALIDATE_OTP_IS_DIGIT.test(char)) {
            items.push(char);
          } else {
            items.push('');
          }
        }
    
        return items;
      }, [otpValue, otpLength]);
    
      const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling =
          target.nextElementSibling as HTMLInputElement | null;
    
        if (nextElementSibling) {
          nextElementSibling.focus();
        }
      };
      const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling =
          target.previousElementSibling as HTMLInputElement | null;
    
        if (previousElementSibling) {
          previousElementSibling.focus();
        }
      };
      const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = (VALIDATE_OTP_IS_DIGIT).test(targetValue);
    
        if (!isTargetValueDigit && targetValue !== '') {
          return;
        }
    
        const nextInputEl = target.nextElementSibling as HTMLInputElement | null;
    
        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
          return;
        }
    
        targetValue = isTargetValueDigit ? targetValue : ' ';
    
        const targetValueLength = targetValue.length;
    
        if (targetValueLength === 1) {
          const newValue = otpValue.substring(0, idx) + targetValue + otpValue.substring(idx + 1);
          
          onChange(newValue);
    
          if (!isTargetValueDigit) {
            return;
          }
    
          focusToNextInput(target);
        } else if (targetValueLength === otpLength) {
          onChange(targetValue);
    
          target.blur();
        }
      };
      const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;
    
        if (key === 'ArrowRight' || key === 'ArrowDown') {
          e.preventDefault();
          return focusToNextInput(target);
        }
    
        if (key === 'ArrowLeft' || key === 'ArrowUp') {
          e.preventDefault();
          return focusToPrevInput(target);
        }
    
        const targetValue = target.value;
    
        // keep the selection range position
        // if the same digit was typed
        target.setSelectionRange(0, targetValue.length);
    
        if (e.key !== 'Backspace' || targetValue !== '') {
          return;
        }
    
        focusToPrevInput(target);
      };
      const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;
    
        // keep focusing back until previous input
        // element has value
        const prevInputEl =
          target.previousElementSibling as HTMLInputElement | null;
    
        if (prevInputEl && prevInputEl.value === '') {
          return prevInputEl.focus();
        }
    
        target.setSelectionRange(0, target.value.length);
      };
    
      return (
        <div className="otp-feild d-flex align-items-center">
          {valueItems.map((digit, idx) => (
            <input
              {...props}
              key={idx}
              type="text"
              inputMode="numeric"
              autoComplete="off"
              pattern="\d{1}"
              maxLength={otpLength}
              className={styleclasses}
              value={digit}
              autoFocus={true}
              onChange={(e) => inputOnChange(e, idx)}
              onKeyDown={inputOnKeyDown}
              onFocus={inputOnFocus}
            />
          ))}
        </div>
      );
}

export default OTPInput;