import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../../app/modules/auth";
import * as template from "../../app/modules/template/redux/TemplateListRedux";
import * as templateUserData from '../../app/modules/template/redux/TemplateUserDataRedux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  template: template.reducer,
  templateUserData: templateUserData.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga(),template.TemplateListSaga(),templateUserData.TemplateUserDataSaga()]);
}
