import React from "react";
import { PageTitle } from "../../../_start/layout/core";
export const AboutusNew: React.FC = () => {

    return (
        <>
            <div className="aboutusNew text-center w-100 mw-md-75 m-auto">
                <h3 className="text-dark fs-1 fw-bolder lh-lg">About Pocket Site</h3>
                <p className="mb-20">Lorem ipsum dolor sit amet consectetur adipisicing elit  ipsum dolor sit amet consectetur adipisicing elit.</p>
                <div className="bottm-content w-100 mw-md-75 m-auto">
                    <p className="fs-1 lh-lg">Vision</p>
                    <p className="mb-8">Create economic opportunity for every member of the global workforce. Sit amet consectetur adipisicing elit  ipsum dolor sit</p>
                    <p className="fs-1 lh-lg">Mission</p>
                    <p className="mb-8"> Consectetur adipisicing elit. Praesentium vel quos, quo iure deleniti ipsa consequatur inventore? Nobis amet, libero nisi cumque enim sit tempore nostrum quasi, dolore quia dicta!.</p>
                    <p className="fs-1 lh-lg">Our work strategy</p>
                    <p className="mb-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, cupiditate officiis! Molestiae distinctio cum optio assumenda ad unde maiores, quidem alias asperiores eligendi autem? Nam, excepturi veniam. Quo, voluptates! Ullam.</p>
                </div>
            </div>


            <PageTitle>About us new</PageTitle>
        </>
    );
};

