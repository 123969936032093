function Footer() {

  return (
    <>
      <div className="footer border-top bg-white p-lg-4 pt-3 pb-3">
        <div className="container"> 
            <div className="copy-right">
              {new Date().getFullYear()} ©Pocketsite.me. All rights reserved 
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
