/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, useState, Suspense } from "react";
import { useDispatch } from "react-redux";
import { getTemplateUserDataByMobileNumber } from "./app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "./app/modules/template/redux/TemplateUserDataRedux";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import Loading from "./_start/helpers/components/Loading";
import Modal from "react-bootstrap/Modal";
import { downloadVCFFile } from "./downloadVCF";

export type OnHideFunction = () => void

type Props = {
  mobileNumber: string;
};

const importView = (tempId: number) =>
  lazy(() =>
    import(
      `./templates/Template0${tempId}`
    ).catch(() => import(`./app/pages/NotFound/NotFoundComponent`))
  );

export const PocketWebsite: React.FC<Props> = ({ mobileNumber }) => {
  const dispatch = useDispatch();
  const [views, setViews] = useState<any>();
  let last10Digit : string;
  
  

  if(mobileNumber.length >= 10) {
    last10Digit = mobileNumber.substring(mobileNumber.length - 10)
  }else { 
    last10Digit = mobileNumber
  }

  async function loadViews(tempId: number, res?: any) {
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      const naid: string = nanoid(10);
      return <View key={naid} editMode={true} />;
    };
    componentPromises(tempId).then(setViews);
  }

  const getUSerTemplateData = () => {
    getTemplateUserDataByMobileNumber(last10Digit)
    // getTemplateUserDataByMobileNumber(mobileNumber)
      .then((res) => {
        // setUserData({ ...res.data.data[0] });
        // setMetaData([
        //   {
        //     property: "og:title",
        //     content: `${res.data.data[0]?.firstName} ${res.data.data[0]?.lastName}`,
        //   },
        //   {
        //     property: "og:description",
        //     content: `${res.data.data[0]?.description}`,
        //   },
        //   {
        //     property: "og:url",
        //     content: `https://pocketsite.me/${res.data.data[0].mobileNumber}`,
        //   },
        //   {
        //     property: "og:type",
        //     content: `website`,
        //   },
        //   {
        //     property: "og:image",
        //     content: `/media/logos/main-logo.png`,
        //   },
        // ]);

        if(res.data.data[0].status === "Published" || res.data.data[0].status === "published"){
          dispatch(
            templateUserDataRedux.actions.setTemplateUserData({
              ...res.data.data[0],
            })
          );
          const tempId = res.data.data[0].selectedTemplateID;
          loadViews(tempId, res.data.data[0]);
          }else{
            loadViews(-1);
          }
          
      })
      .catch((e) => {
        toast.error("The card you are looking for doesn't exist")
        loadViews(-1);
      });
  };

  useEffect(() => {
    getUSerTemplateData();
  }, [mobileNumber, last10Digit]);

  return (
    <>
      {/* {!_isEmpty(metaData) && (
          <Helmet>
            <title>App Title</title>
            <meta name="description" content="App Description" />
            <meta name="theme-color" content="#008f68" />
          </Helmet>
        )} */}
      <div className="d-flex justify-content-center mt-3 mb-3 overflow-auto">
        <Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          {views}
        </Suspense>
      </div>
    </>
  );
};
