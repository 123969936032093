import Buttons from "../Buttons";
import Form from "react-bootstrap/Form";
import InputField from "../edit-template/InputField";
import { useState } from "react";
import { addSubscribers } from "../../app/modules/template/redux/TemplateUserDataCRUD";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { InitSubscribeForm } from "../../app/pages/templates/components/Templates/common/subscribeHelper/InitSubscribeFormModal";
import { SubscribeFormValidate } from "../../app/pages/templates/components/Templates/common/subscribeHelper/SubscribeFormValidate";

function StayConnected() {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: InitSubscribeForm,
    validationSchema: SubscribeFormValidate,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      if(values){
        addSubscribers({
          ...values, status: "Published"
        })
          .then((res) => {
            setSubmitting(false);
            toast.success("Success...! Thank you for subscribing.");
            setLoading(false);
            resetForm();            
          })
          .catch((e) => {
            setSubmitting(false);
            console.log("Error...! Something went wrong");
            setLoading(false);
          });
      }
    }
  });
 
  return (
    <>
      <div className="stay-connected-section" id="contacts">
        <div className="container">
          <div className="text-center">
            <h2 className="heading-medium">Stay connected with us</h2>
            <p className="mb-40">
              Ignite your success: Subscribe to our newsletter and stay ahead of the game!
            </p>
          </div>
          <Form className="mx-auto" onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-center stay-connected-form">
              <div className="floating-group mb-sm-0 mb-4 ">
                <InputField
                  type="email"
                  className="mb-0 w-100"
                  placeholder=" "
                  name="email"
                  autoFocus={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleBlur}
                  value={formik?.values?.email}
                />
                <label className="float-label">Your email here</label>
              </div>

              <Buttons
                btnText={loading ? "Please Wait" : "Subscribe"}
                buttonName={"button"}
                className="ms-sm-3 px-4"
                type={"submit"}
                disabled={loading ? true : false}
              ></Buttons>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default StayConnected;
