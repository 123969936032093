import React from "react";
import "../../scss/template/switch.scss";

type Props = {
  id?: string;
  htmlFor?: string;
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Switch = (props: Props) => {
  return (
    <>
      <div className="switch">
        <input
          type="checkbox"
          id={props.id}
          onChange={props.onChange}
          checked={props.checked}
          name={props.name}
          defaultChecked={props.defaultChecked}
          value={props.checked ? "on" : "off"}
        />
        <label htmlFor={props.htmlFor} className="slider-s round"></label>
      </div>
    </>
  );
};
export default Switch;
