/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { FallbackView } from "../_start/partials";
import { AuthPage } from "./modules/auth";
import { Logout } from "./modules/auth/Logout";
import { EditTemplate } from "./pages/templates/EditTemplate";
import { useDispatch, useSelector } from "react-redux";
import { Home } from "./pages/Home/Home";
import { PocketWebsite } from "../PocketWebsite";
import ProfilePage from "./modules/profile/ProfilePage";
import NotFoundComponent from "./pages/NotFound/NotFoundComponent";
import { Contactus } from "./pages/Contact/Contactus";
import { Aboutus } from "./pages/About/Aboutus";
import { AboutusNew } from "./pages/About/AboutusNew";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthDataRedux } from "./modules/auth/redux/ReduxDispatchExport";
import { checkToken } from "./helper/helper";
import Dashboard from "../pages/Dashboard";

type Props = {
  basename: string;
};

const App: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [siteView, setSiteView] = useState<boolean>(false);
  const auth = useSelector((state: any) => state.auth);

  var url = window.location.href;
  var mobileNumberCheck = url.split("/");
  var mobileNumber = mobileNumberCheck[url.split("/").length - 1];
  
  useEffect(() => {
    if(mobileNumber.length >= 10) {
        if(/^[0-9]*$/.test(`${mobileNumber}`)){
          setSiteView(true);
        }
      }else{
        if (/^\d{10}$/.test(`${mobileNumber}`)) {
          setSiteView(true);
        }else{
          setSiteView(false)
        }
      }
  }, []);

  useEffect(() => {
    if (checkToken(auth)) {
      dispatch(AuthDataRedux.actions.logout());
    }
  }, [auth.isAuthorized]);

  return (
    <>
        {siteView ? (
          <PocketWebsite mobileNumber={mobileNumber} />
          ) : (
            <ThemeProvider>
              <Suspense fallback={<FallbackView />}>
                <Routes>
                  <Route path="*" element={!auth?.isAuthorized ? <></> : <NotFoundComponent />}></Route>
                  <Route path="/home" element={!auth?.isAuthorized ? <Home /> : <Navigate replace to="/profile/preview" />}></Route>
                  <Route path="/home/:section" element={!auth?.isAuthorized ? <Home /> : <Navigate replace to="/profile/preview" />}></Route>
                  <Route path="/" element={!auth?.isAuthorized ? <Navigate replace to="/home" /> : <Navigate replace to="/profile/preview" />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/contactus" element={<Contactus />}></Route>
                  <Route path="/aboutus" element={<Aboutus />}></Route>
                  <Route path="/aboutus-new" element={<AboutusNew />}></Route>
                  <Route path="auth/*" element={<AuthPage />} />
                  <Route path="/login" element={<AuthPage />} />
                  <Route path="/edit-template/:id" element={<EditTemplate />} />
                  <Route path="/profile/*" element={<ProfilePage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                </Routes>
              </Suspense>
          </ThemeProvider>
        )}
        <ToastContainer
          containerId='toast-id'
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </>
  );
};

export { App };
